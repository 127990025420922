import React, { useState, useEffect } from 'react';
import createCar from '../../../api/cars/createCar';
import { json, useNavigate } from 'react-router-dom';
import styles from '../../Client/NewClient/ClientCreate/clientCreate.module.css';
// components
import Form from '../../../components/Form/Form';
import Button from '../../../components/Button/Button';
import axios from '../../../plugins/axios';
import Alert from '../../../components/Alert/Alert';
import Loader from '../../../components/Loader/Loader';
import alert from '../../../helpers/alert';
import getClients from '../../../api/clients/getClients';
import HookForm from '../../../components/HookForm/HookForm';

const formStyles = {
  dealInfo: { display: 'grid', gap: '20px', gridTemplateColumns: '1fr 1fr 1fr 1fr' },
  completeSet: { display: 'grid', gap: '20px', gridTemplateColumns: '1fr 1fr' },
  other: { display: 'grid', gap: '20px', gridTemplateColumns: '1fr' },
};

const CarCreate = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [carInfo, setCarInfo] = useState([
    { type: 'selectContainers', value: undefined },
    { type: 'selectComplectations', value: undefined, params: () => carInfo[0].value },
    { type: 'input', value: undefined, label: 'Год выпуска', placeholder: 'Укажите год выпуска', inputType: 'number' },
    { type: 'input', value: undefined, label: 'VIN', placeholder: 'Укажите VIN', 
      maxLength: 17
    },
    {
      type: 'selectColor',
      value: undefined,
      label: 'Цвет кузова',
      placeholder: 'Укажите цвет',
      style: {
        height: 44,
      },
    },
    {
      type: 'input',
      value: undefined,
      label: 'Цена',
      placeholder: 'Укажите цену',
      format: 'number',
    },
    { type: 'input', value: undefined, label: 'Объем двигателя', placeholder: 'Укажите объем двигателя' },
    // { type: 'input', value: undefined, label: 'Общий объем', placeholder: 'Укажите общий объем' },
    { type: 'input', value: undefined, label: 'Мощность', placeholder: 'Укажите мощность' },
    { type: 'selectTransmissions', value: undefined, label: 'Тип коробки передач', placeholder: 'Укажите тип' },
    { type: 'selectWd', value: undefined, label: 'Тип привода', placeholder: 'Укажите тип' },
    // {
    //   type: 'input',
    //   value: undefined,
    //   label: 'РРЦ, KZT',
    //   placeholder: 'Рекомендуемая розничная цена',
    //   format: 'number',
    // },
    { type: 'input', value: undefined, label: 'Кол-во в наличии', placeholder: 'Количество' },

    { type: 'selectCity', value: undefined },

    {
      type: 'select',
      value: undefined,
      label: 'Дилер',
      placeholder: 'Выберите из списка',
      options: [
        { value: 1, label: 'Завод' },
        { value: 2, label: 'Дилер' },
      ],
    },
  ]);

  const [completeSet, setCompleteSet] = useState([
    { type: 'date', value: undefined, label: 'Дата прибытия в дилерский центр', placeholder: 'дд-мм-гггг' },
    {
      type: 'input',
      value: undefined,
      label: 'Скидка дилерского центра',
      placeholder: 'Укажите скидку в процентах',
      inputType: 'number',
    },
  ]);

  const [other, setOther] = useState([
    {
      type: 'select',
      value: undefined,
      label: 'Ответственный менеджер',
      placeholder: 'Выберите из списка',
      options: [
        { value: 1, label: 'Бахытжан Шагабутдинов' },
        { value: 2, label: 'Бахытжан Шагабутдинов' },
      ],
    },
  ]);

  useEffect(() => {
    getClients({ roleId: 2 }).then((res) => {
      const managers = res.data.users.map((item) => {
        return { value: item.id, label: item.name + ' ' + item.surname };
      });
      setOther([
        {
          type: 'select',
          value: undefined,
          label: 'Ответственный менеджер',
          placeholder: 'Выберите из списка',
          options: managers,
        },
      ])
    });
  }, [])

  const [file, setFile] = React.useState({});

  const validateForm = () => {
    if(!carInfo?.[3]?.value || carInfo?.[3]?.value?.trim()?.length != 17 ) {
      alert.error('Длина VIN должна быть 17 символов');
    }

    return true;
  }

  const createNewCar = () => {
    if(!validateForm()) return;

    // setLoading(true);
    let data = new FormData();
    data.append(
      'json',
      JSON.stringify({
        phid: 5, // уточнить что такое phid
        containerId: carInfo[0].value,
        price: parseInt(carInfo[5].value.replace(/[^\d]/g, '')),
        arrivalDate: completeSet[0].value,
        vin: carInfo?.[3]?.value?.trim(),
        complectationId: carInfo[1].value,
        color: carInfo[4].value,
        // recomendedPrice: carInfo[10].value.replace(/[^\d]/g, ''),
        saleForeDealer: completeSet[1].value,
        paid: 5124, // уточнить что такое paid
        engine_volume: carInfo[6].value,
        // engine_vollume: carInfo[7].value,
        power: carInfo[7].value,
        transmissionId: carInfo[8].value,
        wdId: carInfo[9].value,
        paidTypeId: 1, // уточнить откуда значение paidTypeId берется
        managerId: other[0].value,
        issueYear: carInfo[2].value,
        cityId: carInfo[11].value,
        countryId: 113,
      })
    );
    data.append('count', `${carInfo[9].value}`);
    data.append('dealerId', `${carInfo[12].value}`);
    data.append('photo', file.photo);
    axios
      .post('/api/crm/cars/createCar', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        alert.success('Автомобиль успешно создан');
        setTimeout(() => {
          navigate('/cars');
        }, 1000);
      })
      .catch((error) => {
        alert.error(error.response.data.errorMessage ?? JSON.stringify(error.response))
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      {loading && <Loader />}
      <div style={{ paddingBottom: '20px', marginTop: 20 }}>
        <div className={styles.newClientDataPhoto}>
          <label>
            <input
              type="file"
              onChange={(event) => {
                setFile({ photo: event.target.files[0] });
              }}
            />
            <img alt="" />
            <p>Загрузить фото с пк</p>
            <span>Изображение в формате PNG или JPEG не более 200 Кб</span>
          </label>
        </div>
        <Form
          inputs={carInfo}
          title={'Информация об автомобиле'}
          grid={formStyles.dealInfo}
          handlerSetState={setCarInfo}
          border
        />
        <Form
          inputs={completeSet}
          title={'Информация о дилерском центре'}
          grid={formStyles.completeSet}
          handlerSetState={setCompleteSet}
          border
        />
        <Form inputs={other} title={'Дополнительно'} grid={formStyles.other} handlerSetState={setOther} border />
        <Button text={'Сохранить и продолжить'} fill onClick={createNewCar} disabled={loading} />
      </div>
    </>
  );
};

export default CarCreate;
