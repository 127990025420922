import React, { useEffect, useState } from 'react';

import SelectDocumentType from '../..//../components/Selects/SelectDocumentType';
import { textStyles } from '../..//../constants/textStyles';
import Button from '../..//../components/Button/Button';
import DocsInputNew from '../..//../components/Inputs/DocsInput/DocsInputNew';
import uploadDocumentToDeal from '../..//../api/deals/uploadDocumentToDeal';
import Loader from '../..//../components/Loader/Loader';
import LoaderInline from '../../../components/Loader/LoaderInline';
import {
  DEAL_STATUS_CAR_ISSUED,
  DEAL_STATUS_CONTRACT_SIGNED,
  DEAL_STATUS_NOTARIAL_DOCUMENTS_UPLOADED,
  DEAL_STATUS_WAITING_FOR_NOTARIAL_DOCUMENTS,
  DEAL_STATUS_WAITING_FOR_REGISTRATION_BY_CLIENT,
  DOCUMENT_STATUS_SIGNED,
  DOCUMENT_TYPES_CONTRACT,
  DOCUMENT_TYPES_INVOICE,
  DOCUMENT_TYPES_OPTIONS,
  DOCUMENT_TYPES_POWER_OF_ATTORNEY,
  DOCUMENT_TYPES_SPOUSE_CONSENT,
  DOCUMENT_TYPES_TECH_PASSPORT,
  PAYMENT_STATUS_PAID,
  PAYMENT_TYPES_CREDIT_CARD,
  PAYMENT_TYPES_CREDIT_INVOICE,
  PAYMENT_TYPES_PREPAYMENT_CARD,
} from '../../../constants';
import alert from '../../../helpers/alert';
import editDeal from '../../../api/deals/editDeal';
import generateLink from '../../../api/signature/generateLink';
import { socket } from '../../../App';
import setStep from '../../../api/deals/setStep';
import Select from '../../../components/Select/Select';
import Modal from '../../../components/Modal/Modal';
import getOgpoLink from '../../../api/shop/getOgpoLink';
import AddButton from '../../../components/AddButton/AddButton';
import { colors } from '../../../constants/colors';
import HookForm from '../../../components/HookForm/HookForm';
import generateAllowedDocumentsJson from '../../../utils/generateAllowedDocumentsJson';

const DealCreateDocs = ({
  onSubmit,
  documents,
  deal,
  noSubmit,
  title = 'Документы сделки',
  setDocumentsForm,
  canSkip,
  update,
  backHandler,
  cars,
}) => {
  const [file, setFile] = useState(null);
  const [registrationType, setRegistrationType] = useState(null);

  const saveDeal = () => {
    editDeal({
      id: deal?.id,
      statusId: registrationType?.value,
    })
      .then(() => {
        if (registrationType?.value === DEAL_STATUS_WAITING_FOR_REGISTRATION_BY_CLIENT) {
          setStep({
            dealId: deal?.id,
            step: 6,
          }).then(() => {
            update && update();
          });
        } else {
          update && update();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const row = () => [
    {
      component: SelectDocumentType,
      name: 'fileTypeId',
      style: {
        width: '100%',
      },
      disabled: true,
      defaultValue: DOCUMENT_TYPES_OPTIONS?.find((i) => i.value === DOCUMENT_TYPES_CONTRACT),
    },
    {
      component: DocsInputNew,
      name: 'file',
      label: 'Файл документа',
      text: 'Файл документа',
      id: Math.random(),
      rules: {
        required: true,
      },
    },
  ];
  const [form, setForm] = useState([row()]);
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingSignButton, setLoadingSignButton] = useState(false);
  const [loadingPaymentButton, setLoadingPaymentButton] = useState(false);

  const isNotarialDocumentsUploaded = () => {
    if (!documents?.find((doc) => doc?.typeId === DOCUMENT_TYPES_POWER_OF_ATTORNEY)) {
      return false;
    }

    if (
      !documents?.find((doc) => doc?.typeId === DOCUMENT_TYPES_SPOUSE_CONSENT) &&
      (deal?.paymentType?.id === PAYMENT_TYPES_CREDIT_CARD || deal?.paymentType?.id === PAYMENT_TYPES_CREDIT_INVOICE)
    ) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (setDocumentsForm) {
      const fields = form.map((i) => i.map((i) => i.value?.value || i.value));
      setDocumentsForm(
        fields.map((field) => ({
          dealId: deal?.id,
          fileTypeId: field[0],
          file: field[1],
        }))
      );
    }
  }, [form, setDocumentsForm, deal?.id]);

  const uploadContract = (file) => {
    setLoading(true);
    uploadDocumentToDeal({
      dealId: deal?.id,
      statusId: DOCUMENT_STATUS_SIGNED,
      fileTypeId: DOCUMENT_TYPES_CONTRACT,
      file: file,
      signed: true,
    })
      .then(() => {
        editDeal({
          id: deal?.id,
          statusId: DEAL_STATUS_CONTRACT_SIGNED,
        })
          .then(() => {
            alert.success('Договор успешно загружен');
            update && update();
          })
          .catch((err) => {
            alert.error('Ошибка при смене статуса сделки');
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch(() => {
        alert.error('Ошибка загрузки договора');
        setLoading(false);
      });
  };

  const isContractSigned = documents?.find(
    (i) => i?.typeId === DOCUMENT_TYPES_CONTRACT && i?.statusId === DOCUMENT_STATUS_SIGNED
  );
  const isDealPaid = deal?.statusPayment?.id === PAYMENT_STATUS_PAID;

  const onNext = () => {
    setLoading(false);
    setStep({
      dealId: deal?.id,
      step: 6,
    }).then(() => {
      update && update();
    });
  };

  const sendContractSms = () => {
    setLoadingSignButton(true);
    const contract = documents?.find((i) => i?.typeId === DOCUMENT_TYPES_CONTRACT);

    generateLink({
      url: contract?.url,
      name: 'docs',
      typeId: DOCUMENT_TYPES_CONTRACT,
      documentId: contract?.id,
      userId: contract?.userId,
      dealId: deal?.id,
    })
      .then(() => {
        alert.success('СМС успешно отправлено');
      })
      .catch(() => {
        alert.error('Ошибка отправки СМС');
      })
      .finally(() => {
        setLoadingSignButton(false);
      });
  };
  const sendPaymentSms = () => {
    setLoadingPaymentButton(true);
    editDeal({
      dealId: deal?.id,
      id: deal?.id,
      paymentTypeId: PAYMENT_TYPES_PREPAYMENT_CARD,
      statusId: deal?.status?.id,
    })
      .then(() => {
        alert.success('СМС успешно отправлено');
      })
      .catch(() => {
        alert.error('Ошибка отправки СМС');
      })
      .finally(() => {
        setLoadingPaymentButton(false);
      });
  };

  useEffect(() => {
    const contract = documents?.find((i) => i?.typeId === DOCUMENT_TYPES_CONTRACT);
    socket.on('listenSignatureAgreementDocumentInDeal' + contract?.id, (data) => {
      if (data?.success) {
        update && update();
      } else {
        alert.error('Ошибка подписания документа');
      }
    });

    return () => {
      socket.off('listenSignatureAgreementDocumentInDeal' + contract?.id);
    };
  }, [documents, update]);

  useEffect(() => {
    const listenerName = 'listenCreditCardPaymentChunk' + deal?.chunkId;
    socket.on(listenerName, (data) => {
      if (data?.success) {
        update && update();
      } else {
        alert.error('Ошибка оплаты');
      }
    });

    return () => {
      socket.off(listenerName);
    };
  }, [deal?.chunkId, update]);

  useEffect(() => {
    const listenerName = 'listenToChangeDealStatus' + deal?.id;

    console.log('listenerName', listenerName);
    socket.on(listenerName, (data) => {
      console.log('listenerName DATA', data);

      update && update();
    });
    return () => {
      socket.off(listenerName);
    };
  }, [deal, update]);

  const [ogpoModal, setOgpoModal] = useState(false);
  const [modalOGPO, setModalOGPO] = useState(false);
  const [ogpoLink, setOgpoLink] = useState(null);
  const [ogpoLoading, setOgpoLoading] = useState(false);

  const openOGPO = () => {
    setOgpoLoading(true);
    getOgpoLink(cars[0]?.orderId)
      .then((res) => {
        setOgpoLink(res?.data);
        setOgpoModal(true);
        setModalOGPO(false);
      })
      .catch((err) => {
        alert.error('Ошибка при получении ссылки покупки ОГПО');
      })
      .finally(() => {
        setOgpoLoading(false);
      });
  };

  const onNotarialDocsSubmit = async (values) => {
    setLoading(true);

    if (!values['доверенность']) {
      alert.error('Загрузите доверенность на постановку на учет');
      setLoading(false);
      return;
    }

    await uploadDocumentToDeal({
      dealId: deal?.id,
      fileTypeId: DOCUMENT_TYPES_POWER_OF_ATTORNEY,
      file: values['доверенность'],
    });
    editDeal({
      id: deal?.id,
      dealId: deal?.id,
      statusId: DEAL_STATUS_NOTARIAL_DOCUMENTS_UPLOADED,
      power_of_attorney_number: values?.powerOfAttorneyNumber
        ? values?.powerOfAttorneyNumber
        : deal?.power_of_attorney_number,
      ...generateAllowedDocumentsJson([
        DOCUMENT_TYPES_INVOICE,
        DOCUMENT_TYPES_POWER_OF_ATTORNEY,
        DOCUMENT_TYPES_CONTRACT,
        DOCUMENT_TYPES_TECH_PASSPORT,
      ]),
    })
      .then(() => {
        update();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (loading) {
    return (
      <div style={{ padding: '10px 20px', backgroundColor: 'white', marginTop: '20px', border: '1px solid #E0E0E0' }}>
        <LoaderInline />
      </div>
    );
  }

  return (
    <>
      {loadingPage ? (
        <Loader />
      ) : (
        <div>
          <div style={{ display: 'flex', alignItems: 'center', columnGap: '20px', marginTop: 20, marginBottom: 20 }}>
            <p style={textStyles.formTitle}>{title}</p>
          </div>
          <div style={{ marginBottom: 40 }}>
            {documents?.map((document) => (
              <a
                href={document?.url}
                target="_blank"
                style={{
                  gap: 7,
                  display: 'grid',
                  gridTemplateColumns: 'auto 1fr',
                  alignItems: 'center',
                  marginBottom: 20,
                }}
                rel="noreferrer"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none">
                  <path
                    d="M2 16H18V9H20V17C20 17.2652 19.8946 17.5196 19.7071 17.7071C19.5196 17.8946 19.2652 18 19 18H1C0.734784 18 0.48043 17.8946 0.292893 17.7071C0.105357 17.5196 0 17.2652 0 17V9H2V16ZM12 6H17L10 13L3 6H8V0H12V6Z"
                    fill="#D9BC92"
                  />
                </svg>
                <a style={{ color: '#D9BC92', fontSize: 16, fontWeight: 400 }}>
                  {document?.type?.title} - {document?.url?.split('/')?.pop()}
                </a>
              </a>
            ))}
          </div>

          {form?.length > 0 && !noSubmit && !isContractSigned && isDealPaid && (
            <div style={{ display: 'flex', alignItems: 'center', columnGap: '20px', marginTop: 20, marginBottom: 20 }}>
              <p style={textStyles.formTitle}>Загрузить подписанный договор </p>
            </div>
          )}
          {deal?.power_of_attorney_number && (
            <>
              <div
                style={{ display: 'flex', alignItems: 'center', columnGap: '20px', marginTop: 20, marginBottom: 20 }}
              >
                <p style={textStyles.formTitle}>Номер доверенности:</p>
              </div>
              <div
                style={{
                  border: '1px solid #6A6A6A',
                  textTransform: 'uppercase',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: 10,
                  ...textStyles.fieldLabel,
                }}
              >
                {deal?.power_of_attorney_number}
              </div>
            </>
          )}
          {!isContractSigned && isDealPaid && (
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 20 }}>
              <SelectDocumentType
                style={{ width: '100%' }}
                defaultValue={DOCUMENT_TYPES_OPTIONS?.find((i) => i.value === DOCUMENT_TYPES_CONTRACT)}
                disabled={true}
              />
              <DocsInputNew
                label="Файл документа"
                text="Файл документа"
                name="fileTypeId"
                id="file"
                onChange={uploadContract}
              />
            </div>
          )}

          {/* <AddButton text="Добавить еще документ" onClick={addRow} /> */}

          {!isContractSigned && isDealPaid && (
            <div style={{ display: 'flex', alignItems: 'center', columnGap: '20px', marginTop: 20, marginBottom: 20 }}>
              <p style={textStyles.formTitle}>Подписание договора on-line</p>
            </div>
          )}

          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 20 }}>
            {!isContractSigned && isDealPaid && (
              <>
                <Button text="Отправить договор" fill onClick={sendContractSms} disabled={loadingSignButton} />
                <div
                  style={{
                    border: '1px solid #6A6A6A',
                    textTransform: 'uppercase',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    ...textStyles.fieldLabel,
                  }}
                >
                  {isContractSigned ? 'Подписан' : 'Не подписан'}
                </div>
              </>
            )}
          </div>
          {isDealPaid &&
            isContractSigned &&
            !isNotarialDocumentsUploaded() &&
            deal?.status?.id === DEAL_STATUS_WAITING_FOR_NOTARIAL_DOCUMENTS && (
              <div>
                <HookForm
                  title={'Загрузка документов'}
                  hint="Проконсультируйте клиента какие именно доверенности и согласия надо сделать. Нужно чтобы клиент запрашивал равнозначность доверенности, это необходимо для того, чтобы можно было выписать доверенность у любого нотариуса.   Так же нужно описать на кого нужно сделать доверенность на постановку на учет. Выписать доверенность на регистрационные действия нужно на ФИО, ИИН человека."
                  grid={{ display: 'grid', gap: 20 }}
                  action={'Сохранить и продолжить'}
                  inputs={[
                    {
                      text: 'Доверенность постановки на учет',
                      label: 'Загрузить документ',
                      name: 'доверенность',
                      id: 'Доверенность постановки на учет',
                      component: DocsInputNew,
                      required: true,
                    },
                    {
                      label: 'Номер доверенности',
                      name: 'powerOfAttorneyNumber',
                    },
                  ]}
                  onSubmit={onNotarialDocsSubmit}
                  loading={loading}
                />
              </div>
            )}
          {isDealPaid && isContractSigned && deal?.status?.id === DEAL_STATUS_CONTRACT_SIGNED && (
            <div style={{ marginBottom: 10 }}>
              <Select
                label="Регистрация ТС"
                placeholder={'Выберите тип регистрации ТС'}
                options={[
                  {
                    value: DEAL_STATUS_WAITING_FOR_REGISTRATION_BY_CLIENT,
                    label: 'Клиент сделает регистрацию ТС самостоятельно',
                  },
                  {
                    value: DEAL_STATUS_WAITING_FOR_NOTARIAL_DOCUMENTS,
                    label: 'Регистрацию ТС будет производить Doscar',
                  },
                ]}
                onChange={setRegistrationType}
              />
            </div>
          )}

          <Modal
            isOpen={modalOGPO}
            onRequestClose={() => {
              setModalOGPO(false);
            }}
            width={460}
            contentStyle={{ height: 'auto', inset: '20% 40px auto 350px' }}
          >
            {ogpoLoading ? (
              <LoaderInline />
            ) : (
              <>
                <h3
                  style={{
                    ...textStyles.formTitle,
                    color: colors.blackPearl,
                    textAlign: 'center',
                    padding: '0 70px',
                    lineHeight: '24px',
                    marginBottom: 30,
                  }}
                >
                  Вы будете перенаправлены на сайт <span style={{ color: colors?.cameo }}>Basel.kz</span> для просчета
                  стоимости и покупки страховки ОГПО
                </h3>
                <p style={{ marginBottom: 30, textAlign: 'center' }}>
                  После покупки полиса, вы сможете продолжить оформление сделки
                </p>
                <Button text="Перейти" fill style={{ width: '100%' }} onClick={openOGPO} />
              </>
            )}
          </Modal>

          <Modal
            wrapperStyle={{
              height: '100%',
            }}
            isOpen={ogpoModal}
            onRequestClose={() => {
              setOgpoModal(false);
            }}
          >
            <iframe
              src={ogpoLink}
              style={{ height: '100%', width: '100%' }}
              frameborder="0"
              title="Онлайн-продажа страховки ОГПО"
            ></iframe>
          </Modal>

          <div style={{ display: 'flex', gap: '20px', marginTop: '20px' }}>
            {(isNotarialDocumentsUploaded() || deal?.status?.id === DEAL_STATUS_WAITING_FOR_REGISTRATION_BY_CLIENT) && (
              <Button text="Далее" onClick={onNext} fill style={{ width: '140px' }} />
            )}
            {registrationType && <Button fill text="Сохранить" onClick={saveDeal} />}

            {backHandler && backHandler()}
          </div>
        </div>
      )}
    </>
  );
};

export default DealCreateDocs;
