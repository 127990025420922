import React, { useEffect, useMemo, useState } from 'react';
import styles from '../deal.module.css';
import { textStyles } from '../../../../constants/textStyles';
import formatNumber from '../../../../utils/formatNumber';
import {
  BANKS,
  DEAL_STATUS_WAITING_FOR_PAYMENT,
  DEAL_STEPS,
  DOCUMENT_TYPES_GUARANTEE_LETTER,
  DOCUMENT_TYPES_INVOICE,
  DOCUMENT_TYPES_PAYMENT_CONFIRMATION,
  DOCUMENT_TYPES_PAYMENT_ORDER,
  PAYMENT_STATUS_PAID,
  PAYMENT_TYPES_CREDIT_CARD,
  PAYMENT_TYPES_CREDIT_INVOICE,
} from '../../../../constants';
import { colors } from '../../../../constants/colors';
import DealCarModal from '../../../../components/Modals/DealCarModal';
import ButtonDelete from '../../../../components/Button/ButtonDelete';
import removeItemsToDeal from '../../../../api/deals/removeItemsToDeal';
import DocumentList from './DocumentsList';
import formatDate from '../../../../utils/formatDate';
import HalykGetGuaranteeLetter from '../HalykFlow/HalykGetGuaranteeLetter';
import CheckBankStatus from '../CommonBankSteps/CheckBankStatus';
import Button from '../../../../components/Button/Button';
import cancelCreditAppeal from '../../../../api/creditPlatform/forte/cancelCreditAppeal';
import alert from '../../../../helpers/alert';
import extractErrorMessage from '../../../../utils/extractErrorMessage';
import DealConfigureSteps from '../../DealConfigureSteps';

const DealCreationSteps = ({
  currentStep,
  setCurrentStep,
  state,
  sum,
  deal,
  equipments,
  services,
  cars,
  removeEquipment,
  removeService,
  documents,
  chosenBankId,
  chosenBankAppealId,
  chosenBankAppeal,
  update,
}) => {
  const [open, setOpen] = useState(false);
  const [containerId, setContainerId] = useState('');
  const [steps, setSteps] = useState([]);

  const showHalykGetGuaranteeLetterButton = useMemo(() => {
    const isGuaranteeLetterPresent = documents?.some((item) => item?.typeId === DOCUMENT_TYPES_GUARANTEE_LETTER);
    return chosenBankId === BANKS.HALYK && !isGuaranteeLetterPresent;
  }, [chosenBankId, documents]);

  const isForteChosen = useMemo(() => {
    return chosenBankId === BANKS.FORTE;
  }, [chosenBankId]);

  const showForteUpdateButton = useMemo(() => {
    return true;
    // const isGuaranteeLetterPresent = documents?.some((item) => item?.typeId === DOCUMENT_TYPES_PAYMENT_ORDER);
    // return chosenBankId === BANKS.FORTE && !isGuaranteeLetterPresent;
  }, [chosenBankId, documents]);

  const cancelForteAppeal = () => {
    cancelCreditAppeal(chosenBankAppealId)
      .then(() => {
        update();
        alert.success('Заявка успешно отменена');
      })
      .catch((err) => {
        alert.error(extractErrorMessage(err) || 'Ошибка при отмене заявки');
      });
  };

  const creditAppeal = useMemo(() => {
    try {
      const json = JSON.parse(deal?.creditAppeal?.json);
      return json;
    } catch (e) {
      return {};
    }
  }, [deal]);

  useEffect(() => {
    let newSteps =
      deal?.paymentType?.id === PAYMENT_TYPES_CREDIT_CARD || deal?.paymentType?.id === PAYMENT_TYPES_CREDIT_INVOICE
        ? [
            {
              title: 'Основная информация',
              subSteps: [1],
            },
            {
              title: 'Доп.оборудование',
              subSteps: [2],
            },
            {
              title: 'Доп.услуги',
              subSteps: [3],
            },
            {
              title: 'Уточнение данных',
              subSteps: [4],
            },
            {
              title: 'Банк',
              subSteps: [5],
            },
            {
              title: 'Документы',
              subSteps: [6],
            },
            {
              title: 'Регистрация залога',
              subSteps: [7],
            },
          ]
        : [
            {
              title: 'Основная информация',
              subSteps: [1],
            },
            {
              title: 'Доп.оборудование',
              subSteps: [2],
            },
            {
              title: 'Доп.услуги',
              subSteps: [3],
            },
            {
              title: 'Оплата',
              subSteps: [4],
            },
            {
              title: 'Документы',
              subSteps: [5],
            },
            {
              title: 'Регистрация ТС',
              subSteps: [6],
            },
          ];

    if (chosenBankId === BANKS.FREEDOM) {
      newSteps = [
        {
          title: 'Основная информация',
          subSteps: [1],
        },
        {
          title: 'Доп.оборудование',
          subSteps: [2],
        },
        {
          title: 'Доп.услуги',
          subSteps: [3],
        },
        {
          title: 'Уточнение данных',
          subSteps: [4],
        },
        {
          title: 'Банк',
          subSteps: [5],
        },
        {
          title: 'Документы',
          subSteps: [
            DEAL_STEPS.FREEDOM.WAITING_FOR_CLIENT_ACTIONS_ON_FREEDOM_SITE,
            DEAL_STEPS.FREEDOM.WAITING_FOR_CLIENT_DOCUMENTS,
            DEAL_STEPS.FREEDOM.WAITING_FOR_PAYMENT,
            DEAL_STEPS.FREEDOM.AUTO_REGISTRATION,
            DEAL_STEPS.FREEDOM.WAITING_FOR_FINAL_ACTIONS,
            DEAL_STEPS.FORTE.AUTO_REGISTRATION,
            DEAL_STEPS.FORTE.NOTARIAL_VERIFICATION,
            DEAL_STEPS.FORTE.WAITING_FOR_CLIENT_DOCUMENTS,
            DEAL_STEPS.FORTE.WAITING_FOR_FINAL_ACTIONS,
            DEAL_STEPS.FORTE.WAITING_FOR_PAYMENT,
            DEAL_STEPS.CLIENT_PAYMENT_APPROVE,
          ],
        },
        {
          title: 'Выдача ТС',
          subSteps: [DEAL_STEPS.FREEDOM.WAITING_FOR_FINAL_ACTIONS, DEAL_STEPS.FORTE.CAR_DELIVERY],
        },
      ];
    }
    if (chosenBankId === BANKS.FORTE) {
      newSteps = [
        {
          title: 'Основная информация',
          subSteps: [1],
        },
        {
          title: 'Доп.оборудование',
          subSteps: [2],
        },
        {
          title: 'Доп.услуги',
          subSteps: [3],
        },
        {
          title: 'Уточнение данных',
          subSteps: [4],
        },
        {
          title: 'Банк',
          subSteps: [5],
        },
        {
          title: 'Документы',
          subSteps: [
            DEAL_STEPS.FORTE.AUTO_REGISTRATION,
            DEAL_STEPS.FORTE.NOTARIAL_VERIFICATION,
            DEAL_STEPS.FORTE.WAITING_FOR_CLIENT_DOCUMENTS,
            DEAL_STEPS.FORTE.WAITING_FOR_FINAL_ACTIONS,
            DEAL_STEPS.FORTE.WAITING_FOR_PAYMENT,
            DEAL_STEPS.FORTE.AUTO_REREGISTRATION,
            DEAL_STEPS.FORTE.WAITING_FOR_CLIENT_ACTIONS_ON_FORTE_SIDE,
            DEAL_STEPS.FORTE.SIGN_CONTRACT,
          ],
        },
        {
          title: 'Выдача ТС',
          subSteps: [
            DEAL_STEPS.FORTE.WAITING_FOR_CLIENT_ACTIONS_ON_FORTE_MOBILE_APP
          ],
        },
      ];
    }
    if (chosenBankId === BANKS.HALYK) {
      newSteps = [
        {
          title: 'Основная информация',
          subSteps: [1],
        },
        {
          title: 'Доп.оборудование',
          subSteps: [2],
        },
        {
          title: 'Доп.услуги',
          subSteps: [3],
        },
        {
          title: 'Уточнение данных',
          subSteps: [4],
        },
        {
          title: 'Банк',
          subSteps: [5],
        },
        {
          title: 'Документы',
          subSteps: [
            DEAL_STEPS.HALYK.NOTARIAL_VERIFICATION,
            DEAL_STEPS.HALYK.WAITING_FOR_PAYMENT,
            DEAL_STEPS.HALYK.AUTO_REGISTRATION,
            DEAL_STEPS.HALYK.WAITING_FOR_FINAL_ACTIONS,
            DEAL_STEPS.CLIENT_PAYMENT_APPROVE,
          ],
        },
        {
          title: 'Выдача ТС',
          subSteps: [DEAL_STEPS.HALYK.CAR_DELIVERY],
        },
      ];
    }
    if (chosenBankId === BANKS.HALYK) {
      newSteps = [
        {
          title: 'Основная информация',
          subSteps: [1],
        },
        {
          title: 'Доп.оборудование',
          subSteps: [2],
        },
        {
          title: 'Доп.услуги',
          subSteps: [3],
        },
        {
          title: 'Уточнение данных',
          subSteps: [4],
        },
        {
          title: 'Банк',
          subSteps: [5],
        },
        {
          title: 'Документы',
          subSteps: [DEAL_STEPS.BEREKE.WAITING_FOR_CLIENT_ACTIONS],
        },
        {
          title: 'Выдача ТС',
          subSteps: [DEAL_STEPS.BEREKE.CAR_DELIVERY],
        },
      ];
    }

    setSteps(newSteps);
  }, [chosenBankId, deal]);

  const navigateToClient = (id) => {
    window.open(`/client/${id}/main-data`, '_blank');
  };

  const openCarModal = (containerId) => {
    setContainerId(containerId);
    setOpen(true);
  };

  const closeCarModal = () => {
    setOpen(false);
  };

  // const [filteredDocuments, setFilteredDocuments] = useState(documents);

  // useEffect(() => {
  //   const filtered = [];
  //   documents?.forEach((item) => {
  //     if (deal?.status?.id === DEAL_STATUS_WAITING_FOR_PAYMENT || deal?.statusPayment?.id === PAYMENT_STATUS_PAID) {
  //       if (item?.type?.id !== DOCUMENT_TYPES_INVOICE || item?.type?.id === DOCUMENT_TYPES_PAYMENT_CONFIRMATION) {
  //         filtered.push(item);
  //       }
  //     }
  //   });

  //   setFilteredDocuments(filtered);
  // }, [deal, documents]);

  return (
    <div className={styles.DealSteps}>
      <p style={{ ...textStyles.formTitle, marginBottom: 20 }}>Новая сделка</p>

      <div className={styles.DealStepsContainer} style={{ gridTemplateColumns: 'repeat(' + steps?.length + ', 1fr)' }}>
        {steps.map((step, index) => {
          const activeStep = steps.find((item) => item.subSteps.includes(currentStep));
          const activeStepIndex = steps.indexOf(activeStep);
          const isDone = activeStepIndex > index;
          return (
            <div
              className={`${styles.DealStepsItem} ${index <= activeStepIndex ? styles.DealStepsItem__active : ''}`}
              // onClick={() => setCurrentStep(index + 1)}
            >
              <div className={styles.DealStepsItemNumber}>
                {isDone ? (
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                    <g clipPath="url(#clip0_3458_54300)">
                      <path
                        d="M10.5 15.172L19.692 5.979L21.107 7.393L10.5 18L4.13599 11.636L5.54999 10.222L10.5 15.172Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_3458_54300">
                        <rect width="24" height="24" fill="white" transform="translate(0.5)" />
                      </clipPath>
                    </defs>
                  </svg>
                ) : (
                  index + 1
                )}
              </div>
              <div className={styles.DealStepsItemText}>{step?.title}</div>
            </div>
          );
        })}
      </div>

      {deal && (
        <>
          <p style={{ ...textStyles.formTitle, marginBottom: 20 }}>Основная информация</p>

          <div className={styles.stepsRow} style={{ marginBottom: 20 }}>
            <p className={styles.stepsRow__name}>Клиент:</p>
            <p
              className={styles.stepsRow__value}
              style={{ color: colors?.cameo, textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => {
                navigateToClient(deal?.client?.id);
              }}
            >
              {deal?.client?.name} {deal?.client?.surname}
            </p>

            <p className={styles.stepsRow__name}>Статус сделки:</p>
            <p className={styles.stepsRow__value}>{deal?.status?.title}</p>

            <p className={styles.stepsRow__name}>Способ оплаты:</p>
            <p className={styles.stepsRow__value}>{deal?.paymentType?.title}</p>
            <p className={styles.stepsRow__name}>Статус оплаты:</p>
            <p className={styles.stepsRow__value}>{deal?.statusPayment?.title}</p>
            <p className={styles.stepsRow__name}>Ответственный менеджер:</p>
            <p className={styles.stepsRow__value}>
              {deal?.manager ? deal?.manager?.surname + ' ' + deal?.manager?.name : null}
            </p>
            {cars?.length > 0 &&
              cars?.map((car) => {
                return (
                  <>
                    <p className={styles.stepsRow__name}>Автомобиль:</p>
                    <p className={styles.stepsRow__value}>
                      <div
                        key={car.id}
                        style={{ ...textStyles.buttonText, cursor: 'pointer' }}
                        onClick={() => openCarModal(car.id)}
                      >
                        ID{car?.id} {car?.mark} {car?.model} {car?.complect} {car?.year}
                      </div>
                    </p>
                    <p className={styles.stepsRow__name}>VIN:</p>
                    <p className={styles.stepsRow__value}>{car?.vin}</p>
                    {deal?.creditAppeal?.createdAt ? (
                      <>
                        <p className={styles.stepsRow__name}>Дата подачи заявки на кредит:</p>
                        <p className={styles.stepsRow__value}>
                          {deal?.creditAppeal?.createdAt ? formatDate(deal?.creditAppeal?.createdAt) : null}
                        </p>
                      </>
                    ) : null}

                    {deal?.creditAppeal ? (
                      <>
                        <p className={styles.stepsRow__name}>Субсидия:</p>
                        <p className={styles.stepsRow__value}>{creditAppeal?.subsidy ? 'Да' : 'Нет'}</p>
                      </>
                    ) : null}
                  </>
                );
              })}
              {chosenBankAppeal?.errorMessage ? (
                <>
                  <p className={styles.stepsRow__name}>Сообщение от банка:</p>
                  <p className={styles.stepsRow__value}>{chosenBankAppeal?.errorMessage}</p>
                </>
              ) : null}
          </div>
        </>
      )}
      {equipments?.length > 0 && (
        <>
          <p style={{ ...textStyles.formTitle, marginBottom: 20 }}>Аксессуары</p>

          <div
            className={styles.stepsRow}
            style={{ marginBottom: 20, gridTemplateColumns: '150px 1fr 0.5fr auto', alignItems: 'center' }}
          >
            {equipments?.map((item, index) => {
              return (
                <>
                  <p className={styles.stepsRow__name}>Аксессуары {index + 1}:</p>
                  <p
                    className={styles.stepsRow__value}
                    style={{ ...textStyles.buttonText, textDecoration: 'underline' }}
                  >
                    {item?.title} {item?.count > 1 ? `(${item?.count})` : null}
                  </p>
                  <p className={styles.stepsRow__value} style={{ ...textStyles.buttonText, color: '#05141F' }}>
                    {formatNumber(item?.price)}
                  </p>
                  {
                    currentStep <= 4 ? <ButtonDelete
                    onClick={() => {
                      removeEquipment && removeEquipment(item?.id);
                    }}
                  /> : <div></div>
                  }
                </>
              );
            })}
          </div>
        </>
      )}
      {services?.length > 0 && (
        <>
          <p style={{ ...textStyles.formTitle, marginBottom: 20 }}>Дополнительные услуги:</p>

          <div
            className={styles.stepsRow}
            style={{ marginBottom: 20, gridTemplateColumns: '150px 1fr 0.5fr auto', alignItems: 'center' }}
          >
            {services?.map((item, index) => {
              return (
                <>
                  <p className={styles.stepsRow__name}>Услуга {index + 1}:</p>
                  <p
                    className={styles.stepsRow__value}
                    style={{ ...textStyles.buttonText, textDecoration: 'underline' }}
                  >
                    {item?.title}
                  </p>
                  <p className={styles.stepsRow__value} style={{ ...textStyles.buttonText, color: '#05141F' }}>
                    {formatNumber(item?.price)}
                  </p>
                  {
                    currentStep <= 4 ? <ButtonDelete
                      onClick={() => {
                        removeService && removeService(item?.id);
                      }}
                    /> : <div></div>
                  }
                </>
              );
            })}
          </div>
        </>
      )}

      {documents?.length > 0 && currentStep > 5 && <DocumentList documents={documents} />}
      <div style={{ marginBottom: 10 }}>
        {showHalykGetGuaranteeLetterButton && (
          <HalykGetGuaranteeLetter appealId={chosenBankAppealId} onSuccess={update} />
        )}
      </div>
      <div style={{ marginBottom: 10 }}>
        {showForteUpdateButton && <CheckBankStatus chunkId={deal?.chunkId} bankId={chosenBankId} onSuccess={update} />}
      </div>
      {isForteChosen && (
        <Button onClick={cancelForteAppeal} fill>
          Отменить заявку в банке Форте
        </Button>
      )}
      {sum > 0 && (
        <>
          <hr style={{ border: '1px solid #D9D9D9', margin: '20px 0' }} />
          <div className={styles.stepsRow}>
            <p className={styles.stepsRow__name}>Сумма сделки, KZT:</p>
            <p className={styles.stepsRow__value} style={{ ...textStyles.buttonText, color: '#05141F' }}>
              {formatNumber(sum)}
            </p>
          </div>
        </>
      )}

      <DealConfigureSteps bankId={chosenBankId} dealId={deal?.id} update={update}/>

      <DealCarModal isOpen={open} onClose={closeCarModal} containerId={containerId} />
    </div>
  );
};

export default DealCreationSteps;
