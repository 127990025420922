import React, { useCallback, useMemo, useState } from 'react';
import { ADDITIONAL_SERVICES } from '../../constants/columns';
import { useTable, useGlobalFilter, usePagination, useSortBy } from 'react-table';
import { servicesOptions } from '../../constants/filterOptions';
import { useNavigate } from 'react-router-dom';
import axios from '../../plugins/axios';
import checkCrmSidebarNotifications from '../../api/notifications/checkCrmSidebarNotifications';
import { setFilters } from '../../utils/setFilters';

// components
import Header from '../../components/Header/Header';
import Table from '../../components/Table/Table';
import TableTabs from '../../components/TableTabs/TableTabs';
import Empty from '../../components/Empty/Empty';
import HookForm from '../../components/HookForm/HookForm';

// icons
import icon from '../../assets/sidebar/services.svg';
import getIntegratioPolesPrice from '../../api/shop/getIntegratioPolesPrice';
import { iconBucket } from '../../utils/icons';
import removeService from '../../api/services/removeAdditionalService';
import { INSURANCE_SERVICE_ID_2_5, INSURANCE_SERVICE_ID_3, INSURANCE_SERVICE_ID_3_5 } from '../../constants';

const tableStyle = { backgroundColor: 'white', marginTop: 10 };
const columns = ADDITIONAL_SERVICES;

const Services = ({
  header = true,
  withCheckboxes,
  submitButton,
  cancelButton,
  onSubmit,
  onCancel,
  paymentType,
  edit = true,
  orderId,
  requiredCheckboxes,
  cityId,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [services, setServices] = React.useState([]);
  const [finansialServices, setFinansialServices] = useState([]);
  const [additionalServices, setAdditionalServices] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [pagination, setPagination] = useState({
    page: 1,
    totalPages: 1,
    hasPreviousPage: false,
    hasNextPage: false,
    pageSize: 10,
  });
  const [isFetchAll, setFetchAll] = useState(false);

  const table_1 = useTable(
    {
      columns,
      data: finansialServices,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const table_2 = useTable(
    {
      columns,
      data: additionalServices,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { setGlobalFilter, page, setPageSize } = table_1;
  const { page: page2 } = table_2;

  const [value, setValue] = useState('');
  const [queryParams, setQueryParams] = useState({});

  const getServices = useCallback(
    async (params) => {
      setLoading(true);
      let ids = [];
      await axios
        .get('/api/crm/additional-services/getAdditionalServices', { params: { ...params, cityId, pageSize: 100, } })
        .then(async (res) => {
          res.data.additionalServices.forEach((item) => {
            item.notifications.forEach((i) => {
              ids.push(i.id);
            });
          });

          let newData = res?.data?.additionalServices;

          if (orderId) {
            newData = await Promise.all(
              res?.data?.additionalServices.map(async (eq) => {
                if (
                  eq.id == INSURANCE_SERVICE_ID_2_5 ||
                  eq.id == INSURANCE_SERVICE_ID_3 ||
                  eq.id == INSURANCE_SERVICE_ID_3_5
                ) {
                  try {
                    const res = await getIntegratioPolesPrice({ orderId: orderId?.value, serviceId: eq.id });
                    console.log(res);

                    eq.price = typeof res?.data != 'number' ? 0 : res?.data;
                    return eq;
                  } catch (err) {
                    return eq;
                  }
                }
                return eq;
              })
            );
          }

          setServices(newData);
          setFinansialServices(() =>
            newData.filter(
              (service) =>
                service.id === INSURANCE_SERVICE_ID_2_5 ||
                service.id === INSURANCE_SERVICE_ID_3 ||
                service.id === INSURANCE_SERVICE_ID_3_5
            )
          );
          setAdditionalServices(() =>
            newData.filter(
              (service) =>
                service.id !== INSURANCE_SERVICE_ID_2_5 &&
                service.id !== INSURANCE_SERVICE_ID_3 &&
                service.id !== INSURANCE_SERVICE_ID_3_5
            )
          );
          setPagination({
            ...res.data.pagination,
            page: res.data.pagination?.pageNumber,
            pageSize: pagination.pageSize,
          });
          setDataCount(res.data.pagination?.count);
        })
        .catch((error) => {})
        .finally(() => setLoading(false));
      if (ids.length > 0) {
        checkCrmSidebarNotifications({ notificationIds: ids })
          .then((res) => {})
          .catch((error) => {});
      }
    },
    [orderId, pagination.pageSize]
  );

  const popover = useMemo(
    () => [
      {
        title: 'Удалить',
        icon: iconBucket(),
        onClick: (row) => {
          setLoading(true);
          removeService({ id: row?.id })
            .then(() => {
              getServices(queryParams);
            })
            .finally(() => setLoading(false));
        },
      },
    ],
    [getServices, queryParams]
  );

  React.useEffect(() => {
    if (!isFetchAll) {
      getServices({ page: pagination.page, pageSize: pagination.pageSize, ...queryParams });
    }
  }, [pagination.page, pagination.pageSize, queryParams]);

  const handleNavigate = () => {
    navigate('/services/add-service');
  };
  const navigateToEdit = (index) => {
    const record = services.filter((item) => item.id === index);
    navigate(`/services/${record[0].id}`);
  };

  const setAllFilters = () => {
    getServices({ all: value });
  };

  const handleActionPopover = async (carIndex, actionIndex, title, id) => {
    console.log(carIndex, actionIndex);
  };

  return (
    <>
      {header && (
        <>
          <Header title="Дополнительные услуги" icon={icon} />
          <br />
          <br />
          <br />
          <br />
        </>
      )}
      <TableTabs
        setFilter={setGlobalFilter}
        options={servicesOptions}
        value={value}
        filterComponent={
          <HookForm
            inputs={servicesOptions}
            grid={{
              display: 'grid',
              gap: '10px',
              gridTemplateColumns: '1fr 1fr 1fr',
            }}
            style={{ backgroundColor: 'transparent' }}
          />
        }
        handleSearchParams={(resetFilters) =>
          setFilters(resetFilters, setFetchAll, setPageSize, setPagination, setQueryParams, servicesOptions)
        }
        setValue={setValue}
        navigate={handleNavigate}
        button={'Добавить услугу'}
        inputPlaceholder={'Быстрый поиск по услугам'}
      />
      <br />
      {!page.length && !page2.length && !loading ? (
        <Empty />
      ) : (
        <>
          <p
            style={{ fontWeight: 700, fontSize: '20px', lineHeight: '20px', color: '#494136', margin: '0px 0 20px 0' }}
          >
            Финансовые услуги
          </p>
          <Table
            table={table_1}
            all={'услуг'}
            sort
            style={tableStyle}
            loading={loading}
            setPagination={setPagination}
            setFetchAll={setFetchAll}
            isFetchAll={isFetchAll}
            setData={setServices}
            setLoading={setLoading}
            queryParams={queryParams}
            api={'/api/crm/additional-services/getAdditionalServices'}
            response={'additionalServices'}
            isSortable
            withCheckboxes={withCheckboxes}
            submitButton={submitButton}
            cancelButton={cancelButton}
            onSubmit={onSubmit}
            onCancel={onCancel}
            paymentType={paymentType}
            dataCount={finansialServices.length}
            edit={edit}
            requiredCheckboxes={requiredCheckboxes}
            popover={popover}
            handleActionPopover={handleActionPopover}
            handleNavigate={navigateToEdit}
          />
          <p
            style={{ fontWeight: 700, fontSize: '20px', lineHeight: '20px', color: '#494136', margin: '40px 0 20px 0' }}
          >
            Дополнительный сервис
          </p>
          <Table
            table={table_2}
            all={'услуг'}
            sort
            style={tableStyle}
            loading={loading}
            setPagination={setPagination}
            setFetchAll={setFetchAll}
            isFetchAll={isFetchAll}
            setData={setServices}
            setLoading={setLoading}
            queryParams={queryParams}
            api={'/api/crm/additional-services/getAdditionalServices'}
            response={'additionalServices'}
            isSortable
            withCheckboxes={withCheckboxes}
            submitButton={submitButton}
            cancelButton={cancelButton}
            onSubmit={onSubmit}
            onCancel={onCancel}
            paymentType={paymentType}
            dataCount={additionalServices.length}
            edit={edit}
            requiredCheckboxes={requiredCheckboxes}
            popover={popover}
            handleActionPopover={handleActionPopover}
            handleNavigate={navigateToEdit}
          />
        </>
      )}
    </>
  );
};

export default Services;
