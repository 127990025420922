import React, { useState } from 'react';
import axios from '../../plugins/axios';
import { useParams, useNavigate } from 'react-router-dom';
import checkCrmSidebarNotifications from '../../api/notifications/checkCrmSidebarNotifications';
import getDealCard from '../../api/deals/getDealCard';
import { useOutletContext } from 'react-router-dom';

// components
import Form from '../../components/Form/Form';
import Button from '../../components/Button/Button';
import SelectClients from '../../components/Selects/SelectClients';
import SelectStatuses from '../../components/Selects/SelectStatuses';
import SelectPaymentTypes from '../../components/Selects/SelectPaymentTypes';
import SelectDealPaymentStatuses from '../../components/Selects/selectDealPaymentStatuses';
import Datepicker from '../../components/Inputs/Datepicker/Datepicker';
import HookForm from '../../components/HookForm/HookForm';
import Loader from '../../components/Loader/Loader';
import Alert from '../../components/Alert/Alert';
import HookModalForm from '../../components/HookModalForm/HookModalForm';
import SelectEquipments from '../../components/Selects/SelectEquipments';
import SelectAdditionalServices from '../../components/Selects/SelectAdditionalServices';
import SelectCreditPeriod from '../../components/Selects/SelectCreditPeriod';

// icons
import edit from '../../assets/editForm.svg';
import SelectAdditionalServicesModal from '../../components/Modals/SelectAdditionalServicesModal';
import addAdditionalServicesToDeal from '../../api/deals/addAdditionalServicesToDeal';
import SelectDealStatuses from '../../components/Selects/SelectDealStatuses';
import alert from '../../helpers/alert';
import SelectManagers from '../../components/Selects/SelectManagers';
import editDeal from '../../api/deals/editDeal';
import createNumberMask from '../../utils/createNumberMask';
import numberMask from '../../utils/numberMask';
import { DEAL_STEPS } from '../../constants';
import setStep from '../../api/deals/setStep';

const formStyles = {
  dealInfo: { display: 'grid', gap: '20px', gridTemplateAreas: `'a a b b' 'c c c c'` },
  creditInfo: { display: 'grid', gap: '20px', gridTemplateColumns: 'auto auto auto auto' },
  other: { display: 'grid', gap: '20px' },
};

const DealMainInfo = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [equipmentsCount, updateCounters] = useOutletContext();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [error, setError] = React.useState({});
  const [succes, setSucces] = React.useState({});
  const [other, setOther] = React.useState([
    {
      type: 'select',
      label: 'Ответственный менеджер',
      component: SelectManagers,
      placeholder: 'Выберите из списка',
      name: 'manager',
    },
    { type: 'input', label: 'Заметки/комментарии о сделке', placeholder: 'Оставьте ваш комментарий', name: 'comment' },
  ]);
  const [dealInfo, setDealInfo] = React.useState([]);
  const [equipmentForm, setEquipmentForm] = useState([
    {
      name: 'services',
      component: SelectAdditionalServices,
      label: 'Услуги',
      placeholder: 'Выберите из списка',
      isMulti: true,
      isSearchable: true,
    },
    {
      name: 'equipments',
      component: SelectEquipments,
      label: 'Оборудование',
      placeholder: 'Выберите из списка',
      isMulti: true,
      isSearchable: true,
    },
  ]);

  const [dealId, setDealId] = React.useState(null);

  const getDeal = async () => {
    await getDealCard({ id: id })
      .then((res) => {
        setDealId(res.data.id);
        const dealInfo = [
          {
            name: 'createdAt',
            type: 'date',
            defaultValue: new Date(res.data.createdAt),
            label: 'Дата',
            component: Datepicker,
          },
          {
            type: 'input',
            name: 'dealId',
            label: 'ID',
            placeholder: '_ _ _ _ _ _ _ _',

            defaultValue: res.data.id,
          },
          {
            component: SelectClients,
            name: 'clientId',
            label: 'Клиент',
            placeholder: 'Выберите из списка',
            isSearchable: true,
            style: { gridArea: 'c' },
            defaultValue: {
              id: res.data?.client?.id,
              label: res.data?.client ? `${res.data?.client?.name} ${res.data?.client?.surname}` : null,
            },
          },
          {
            label: 'Статус сделки',
            name: 'statusId',
            component: SelectDealStatuses,
            defaultValue: {
              value: res?.data?.status?.id,
              label: res?.data?.status?.title,
              paymentTypeId: res.data.paymentType.id,
            },
          },
          {
            label: 'Способ оплаты',
            name: 'paymentTypeId',
            component: SelectPaymentTypes,
            defaultValue: { value: res.data.paymentType?.id, label: res.data.paymentType?.title },
          },
          {
            label: 'Статус оплаты',
            name: 'statusPaymentId',
            component: SelectDealPaymentStatuses,
            placeholder: 'Выберите из списка',
            defaultValue: { value: res.data.statusPayment?.id, label: res.data.statusPayment?.title },
          },
          {
            name: 'sum',
            label: 'Сумма сделки',
            placeholder: 'Автоматический расчет',
            defaultValue: res.data.sum,
            mask: numberMask,
          },
          {
            label: 'Первоначальный взнос',
            name: 'initialFee',
            dependency: 'paymentTypeId',
            dependencyAccessor: (obj) => obj?.value,
            placeholder: 'Введите первоначальный взнос',
            isCredit: true,
            mask: numberMask,
          },
          {
            label: 'Срок кредитования, месяцев',
            name: 'period',
            component: SelectCreditPeriod,
            dependency: 'paymentTypeId',
            dependencyAccessor: (obj) => obj?.value,
          },
        ];
        setDealInfo(dealInfo);

        setOther([
          {
            type: 'select',
            label: 'Ответственный менеджер',
            component: SelectManagers,
            placeholder: 'Выберите из списка',
            name: 'manager',
            defaultValue: res.data.manager
              ? { value: res.data.manager?.id, label: res.data.manager?.name + ' ' + res.data.manager?.surname }
              : null,
          },
          {
            type: 'input',
            label: 'Заметки/комментарии о сделке',
            placeholder: 'Оставьте ваш комментарий',
            name: 'comment',
          },
        ]);

        if (res.data.notifications.length > 0) {
          let ids = res.data.notifications.map((item) => {
            return item.id;
          });
          checkCrmSidebarNotifications({ notificationIds: ids })
            .then((res) => {})
            .catch((error) => {});
        }
      })
      .catch((error) => {
        console.log(error, '[error]');
      })
      .finally(() => setLoading(false));
  };
  const [rerender, setRerender] = React.useState(false);

  const handleSubmit = (data) => {
    setSubmitLoading(true);
    const initialFee = dealInfo[7]?.value;
    const creditPeriod = dealInfo[8]?.value?.value;
    const payload = new Map(Object.entries(data));
    payload.delete('dealId');
    payload.set('id', parseInt(dealInfo[1].defaultValue));
    payload.set('clientId', data?.clientId?.value ?? dealInfo[2].defaultValue.id);
    payload.set('statusId', data?.statusId?.value ?? dealInfo[3].defaultValue.value);
    payload.set('paymentTypeId', data?.paymentTypeId?.value ?? dealInfo[4].defaultValue.value);
    payload.set('statusPaymentId', data?.statusPaymentId?.value ?? dealInfo[5].defaultValue.value);
    payload.set('sum', dealInfo[6].defaultValue);

    axios
      .post('/api/crm/deals/editDeal', Object.fromEntries(payload))
      .then((res) => {
        // if (data.initialFee) {
        //   axios.post('/api/crm/creditPlatform/createCreditAppeal', {
        //     initialFee: parseInt(data.initialFee),
        //     period: parseInt(data.period.value),
        //     dealId: parseInt(dealInfo[1].defaultValue),
        //   });
        // }
        setSucces({ message: 'Сделка Обновлена' });
        setTimeout(() => {
          navigate('/deals');
        }, 1000);
      })
      .catch((error) => alert.error(error.response.data.errorMessage ?? JSON.stringify(error.response)))
      .finally(() => setSubmitLoading(false));
  };

  const addDealComment = (values) => {
    setLoading(true);
    editDeal({
      id: dealId,
      dealId,
      managerId: values?.manager?.value,
      comment: values?.comment,
    }).then(() => {
      alert?.success('Сделка успешно обновлена');
      getDeal();
    }).catch(() => {
      setLoading(false);
    })
  };

  React.useEffect(() => {
    getDeal();
    updateCounters();
  }, []);

  const handleAddEquipments = (data) => {
    console.log(data);
  };

  const handleAddAdditionalServicesSubmit = (orderId, services) => {
    console.log(orderId, services);
    addAdditionalServicesToDeal(orderId, services)
      .then(() => {
        setShowModal(false);
        updateCounters();
      })
      .catch((err) => alert(err));
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {submitLoading && <Loader />}
          <div style={{ paddingBottom: '20px' }}>
            <div style={{ opacity: 100 }}>{rerender}</div>
            <HookForm
              inputs={dealInfo}
              title={'Информация о сделке'}
              grid={formStyles.dealInfo}
              onSubmit={handleSubmit}
              action={'Сохранить и продолжить'}
              border
              isAddEquipments={equipmentsCount.equipments || equipmentsCount.services ? false : true}
              handleAddEquipments={() => setShowModal(true)}
            />
            {dealId && (
              <HookForm
                inputs={other}
                title={'Дополнительно'}
                grid={{ display: 'grid', gap: 20 }}
                onSubmit={addDealComment}
                action={'Сохранить '}
              />
            )}
            <SelectAdditionalServicesModal
              isOpen={showModal}
              onClose={() => setShowModal(false)}
              onSubmit={handleAddAdditionalServicesSubmit}
              dealId={dealId}
            />
            {/* {showModal && (
              <HookModalForm
                title={'Доп. услуги'}
                closeModal={() => setShowModal(false)}
                mainForm={equipmentForm}
                setMainForm={setEquipmentForm}
                grid={formStyles.other}
                action={'Добавить'}
                handleSubmit={handleAddEquipments}
              />
            )} */}
          </div>
                </>
      )}
      {error.message && <Alert message={error.message} onClick={setError} />}
      {succes.message && <Alert message={succes.message} />}
    </>
  );
};

export default DealMainInfo;
