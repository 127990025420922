import ReactModal from 'react-modal';
import { textStyles } from '../../constants/textStyles';
import styles from './Modal.module.css';

const SIDEBAR_WIDTH = 350;

const Modal = ({
  isOpen,
  onRequestClose,
  children,
  title,
  style = {},
  contentStyle = {},
  width = '100%',
  wrapperStyle,
  overlayStyle = {},
  ...props
}) => {
  return (
    <ReactModal
      isOpen={isOpen}
      closeTimeoutMS={200}
      onRequestClose={onRequestClose}
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 100,
          ...overlayStyle
        },
        content: {
          maxWidth: width,
          margin: '0 auto',
          left: SIDEBAR_WIDTH,
          ...contentStyle,
        },
        ...style,
      }}
      {...props}
    >
      <div className={styles.heading}>
        {title && (
          <h3 style={textStyles.formTitle} className={styles.title}>
            {title}
          </h3>
        )}
        <svg
          onClick={onRequestClose}
          className={styles.close}
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.00023 5.586L11.9502 0.636002L13.3642 2.05L8.41423 7L13.3642 11.95L11.9502 13.364L7.00023 8.414L2.05023 13.364L0.63623 11.95L5.58623 7L0.63623 2.05L2.05023 0.636002L7.00023 5.586Z"
            fill="#D9BC92"
          />
        </svg>
      </div>
      <div style={wrapperStyle} className={styles.wrapper}>{children}</div>
    </ReactModal>
  );
};

export default Modal;
