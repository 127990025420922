import Modal from '../../../components/Modal/Modal';
import { INSURANCE_ID } from '../../../constants';
import Services from '../../Services/Services';

const DealCreateServicesModal = ({ isOpen, onClose, onSubmit, onCancel, paymentType, orderId, cityId }) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={onCancel}>
      <Services header={false} onSubmit={onSubmit} onCancel={onCancel} paymentType={paymentType} edit={false} submitButtonLabel="Добавить услуги" orderId={orderId} withCheckboxes requiredCheckboxes={[INSURANCE_ID]} cityId={cityId}/>
    </Modal>
  );
};

export default DealCreateServicesModal;
