import { useState } from 'react';
import checkBankStatus from '../../../../api/bank/checkStatusAppeal';
import generateContract from '../../../../api/deals/generateContract';
import generateLink from '../../../../api/signature/generateLink';
import Button from '../../../../components/Button/Button';
import HookForm from '../../../../components/HookForm/HookForm';
import DocsInputNew from '../../../../components/Inputs/DocsInput/DocsInputNew';
import FormHeading from '../../../../components/Typography/FormHeading';
import { DOCUMENT_TYPES_CONTRACT } from '../../../../constants';
import { textStyles } from '../../../../constants/textStyles';
import alert from '../../../../helpers/alert';
import { SIGN_TYPES } from '../../../Public/SignDocuments';

const ContractSign = ({ deal, documents, cars, onSuccess, dealStepAfterSign, update }) => {
  const [isSmsSent, setIsSmsSent] = useState(false);
  const [loading, setLoading] = useState(false);
  
  const sendSmsForSign = async () => {
    try {
      setLoading(true);
      let contract = documents?.find((i) => i?.typeId == DOCUMENT_TYPES_CONTRACT);
      if (!contract) {
        contract = await generateContract({ orderIds: cars?.map((i) => i?.orderId), chunkId: deal?.chunkId, dealId: deal?.id });
        contract = JSON.parse(contract?.data);
      }

      console.log(contract);
      if(!contract?.documentId && !contract?.id || !contract?.url) {
        throw Error;
      }

      await generateLink({
        url: contract?.url,
        name: 'docs',
        typeId: DOCUMENT_TYPES_CONTRACT,
        documentId: contract?.documentId || contract?.id,
        userId: deal?.client?.id,
        dealId: deal?.id,
        signType: SIGN_TYPES.ECP,
        step: dealStepAfterSign,
      });
      setIsSmsSent(true);

      onSuccess();
    } catch (e) {
      console.log(e);
      alert.error('Ошибка при отправке СМС клиенту');
    } finally {
      setLoading(false);
    }
  };
  return (
    <div>
      <p
        style={{
          ...textStyles.formTitle,
          marginBottom: 20,
          marginTop: 20,
        }}
      >
        Подписание договора купли-продажи
      </p>

      <div style={{display: 'grid', gap: '10px', justifyContent: 'flex-start'}}>
      {!isSmsSent && <Button disabled={loading} text="Отправить СМС со ссылкой на подписание ЭЦП" fill onClick={() => sendSmsForSign(false)} />}
      <Button onClick={update} text="Обновить" fill/>
      </div>
    </div>
  );
};

export default ContractSign;
