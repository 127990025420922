import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import createComplectation from '../../../api/cars/createComplectation';
// components
import Form from '../../../components/Form/Form';
import Header from '../../../components/Header/Header';
import styles from '../../../components/Form/form.module.css';
import Input from '../../../components/Input/Input';
import Button from '../../../components/Button/Button';
// icons
import icon from '../../../assets/sidebar/cars.svg';

const formStyles = {
  complectation: { display: 'grid', gap: '20px', gridTemplateColumns: '1fr 1fr 1fr' },
};

const CarComplectationsCreate = () => {
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  const [complectation, setComplectation] = useState([
    { type: 'input', value: undefined, label: 'ID', placeholder: 'Укажите ID', inputType: 'number' },
    { type: 'input', value: undefined, label: 'Комплектация', placeholder: 'Укажите комплектацию' },
    { type: 'selectEngine', value: 1 },
    { type: 'selectCarcase', value: 1 },
    { type: 'selectWd', value: 2 },
  ]);
  const [safely, setSafely] = useState([{ title: '' }]);
  const [equipments, setEquipments] = useState([{title: ''}]);

  const createComplectationCar = () => {

    createComplectation({
      title: complectation[1].value,
      containerId: searchParams.get('id'),
      engineId: complectation[2].value,
      complectation_safely: safely,
      complectation_equipment: equipments,
      carcaseId: complectation[3].value,
      wdId: complectation[4].value,
    }).then(() => navigate('/cars/complectations'));
  };

  return (
    <>
      <Header title={'Новая комплектация'} icon={icon} />
      <div style={{ paddingBottom: '20px' }}>
        <Form
          inputs={complectation}
          title={'Комплектации'}
          grid={formStyles.complectation}
          action={'Сохранить'}
          showBtn={false}
          handlerSetState={setComplectation}
          handleSubmit={createComplectationCar}
          border
        />
        <div className={styles.borderWrapper}>
          <h1>Безопасноть</h1>
          <br />
          {safely.map((item, index) => (
            <div style={{ marginBottom: 15 }}>
              <Input
                label={'Название'}
                placeholder={'Введите значение'}
                onChange={(e) => {
                  safely[index].title = e.target.value;
                  setSafely([...safely]);
                }}
              />
            </div>
          ))}
          <Button
            onClick={() => {
              safely.push({ title: '' });
              setSafely([...safely]);
            }}
            style={{
              marginTop: 15,
              marginBottom: 15,
            }}
            text={'Добавить'}
            fill
          />
          <h1>Комфорт и оборудование</h1>
          {equipments.map((item, index) => (
            <div style={{ marginBottom: 15 }}>
              <Input
                label={'Название'}
                placeholder={'Введите значение'}
                onChange={(e) => {
                  equipments[index].title = e.target.value;
                  setEquipments([...equipments]);
                }}
              />
            </div>
          ))}
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              onClick={() => {
                equipments.push({ title: '' });
                setEquipments([...equipments]);
              }}
              style={{
                marginTop: 15,
                marginBottom: 15,
              }}
              text={'Добавить'}
              fill
            />
            <Button
              onClick={() => createComplectationCar()}
              style={{
                marginTop: 15,
                marginBottom: 15,
              }}
              text={'Сохранить'}
              fill
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CarComplectationsCreate;
