import Button from '../../../../components/Button/Button';
import DeleteButton from '../../../../components/DeleteButton/DeleteButton';
import Counter from '../../../../components/Inputs/Counter/Counter';
import { colors } from '../../../../constants/colors';
import { textStyles } from '../../../../constants/textStyles';
import styles from './styles.module.css';

const SellEqipmentCart = ({ items, remove, openEquipmentsModal, onCounterChange }) => {
  return (
    <div>
      {items?.map((item) => {
        return (
          <div>
            <p
              style={{
                ...textStyles.formTitle,
                marginBottom: 20,
                display: 'flex',
                gap: 10,
              }}
            >
              Выбрано доп.оборудование к автомобилю:{' '}
              <span
                className={styles.link}
                onClick={(e) => openEquipmentsModal(e.target, item.car)}
                style={{ color: colors.cameo }}
              >
                {item?.carName}
              </span>
              <Button
                style={{ padding: 0, marginLeft: 15 }}
                onClick={() => remove(item.id)}
                children={
                  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="remix-icons/line/system/delete-bin-line" clip-path="url(#clip0_3915_55250)">
                      <g id="Group">
                        <path
                          id="Vector"
                          d="M17.5 6H22.5V8H20.5V21C20.5 21.2652 20.3946 21.5196 20.2071 21.7071C20.0196 21.8946 19.7652 22 19.5 22H5.5C5.23478 22 4.98043 21.8946 4.79289 21.7071C4.60536 21.5196 4.5 21.2652 4.5 21V8H2.5V6H7.5V3C7.5 2.73478 7.60536 2.48043 7.79289 2.29289C7.98043 2.10536 8.23478 2 8.5 2H16.5C16.7652 2 17.0196 2.10536 17.2071 2.29289C17.3946 2.48043 17.5 2.73478 17.5 3V6ZM18.5 8H6.5V20H18.5V8ZM9.5 11H11.5V17H9.5V11ZM13.5 11H15.5V17H13.5V11ZM9.5 4V6H15.5V4H9.5Z"
                          fill="#BABABA"
                        />
                      </g>
                    </g>
                    <defs>
                      <clipPath id="clip0_3915_55250">
                        <rect width="24" height="24" fill="white" transform="translate(0.5)" />
                      </clipPath>
                    </defs>
                  </svg>
                }
              />
            </p>

            <table
              style={{
                width: '100%',
                textAlign: 'left',
              }}
              className={styles.table}
            >
              <thead>
                <tr>
                  <th style={{width: '50%'}}>Название</th>
                  <th style={{width: '25%'}}>Цена</th>
                  <th style={{ width: 20 }}>Количество</th>
                  <th style={{width:'25%'}}>Сумма</th>
                </tr>
              </thead>

              {item.equipments?.map((equipment) => (
                <tr>
                  <td>{equipment?.title}</td>
                  <td>{equipment?.price}</td>
                  <td>
                    <Counter
                      value={equipment?.counter}
                      onIncrement={() => onCounterChange(item.id, equipment?.id, 'increment')}
                      onDecrement={() => onCounterChange(item.id, equipment?.id, 'decrement')}
                      buttonsStyle={{ width: 20, height: 20 }}
                      containerStyle={{
                        gridTemplateColumns: '1fr auto 1fr',
                        margin: 0,
                      }}
                      height={20}
                      inputStyle={{backgroundColor: 'transparent', border: 'none'}}
                    />
                  </td>
                  <td>{equipment?.price * equipment?.counter}</td>
                </tr>
              ))}
            </table>
          </div>
        );
      })}
    </div>
  );
};

export default SellEqipmentCart;
