import React, { useEffect, useState } from 'react';
import styles from '../Input/input.module.css';
import ReactSelect, { components } from 'react-select';
import { useNavigate } from 'react-router-dom';
import icon from '../../assets/buttonIcons/plus.svg';
import { colors } from '../../constants/colors';
import Hint from '../Hint';

const NoOptionsMessage = (props) => {
  return (
    <components.NoOptionsMessage {...props}>
      <AddUserOption />
    </components.NoOptionsMessage>
  );
};

const AddUserOption = () => {
  const navigate = useNavigate();
  return (
    <div
      style={{ display: 'flex', alignItems: 'flex-start', columnGap: '12px', cursor: 'pointer', padding: 10 }}
      onClick={() => navigate('/client/create')}
    >
      <img src={icon} alt="Icon" />
      <p style={{ textDecoration: 'underline', color: colors.cameo }}>Добавить нового клиента</p>
    </div>
  );
};

const Select = ({
  style,
  title,
  label,
  placeholder,
  options,
  state,
  defaultValue,
  handleSetState,
  handleSetSelect,
  isSearchable = false,
  onFocus,
  isAddClient,
  isMulti,
  disabled,
  onChange,
  value,
  error,
  required,
  isLoading = false,
  hint,
  ...props
}) => {
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    if (focused) {
      onFocus && onFocus();
    }
  }, [focused, onFocus]);

  const customTheme = (theme) => {
    return {
      ...theme,
      borderRadius: '0px',
      colors: {
        ...theme.colors,
        primary: colors.cameo,
        primary25: '#F2F2F2',
        primary50: '#F2F2F2',
      },
    };
  };

  React.useEffect(() => {}, [defaultValue]);
  return (
    <div className={styles.customInput}>
      <p>
        {title || label} {required && <span style={{ color: colors.red }}>*</span>}
        {hint && <Hint text={hint} />}
      </p>
      <ReactSelect
        components={
          isAddClient
            ? {
                MenuList: ({ children, ...props }) => (
                  <div {...props}>
                    {children}
                    <AddUserOption />
                  </div>
                ),
              }
            : null
        }
        isSearchable={isSearchable}
        defaultValue={defaultValue}
        value={value}
        onInputChange={(e) => {
          if (handleSetSelect) handleSetSelect(e);
        }}
        options={options}
        onChange={(val) => {
          if (handleSetState) {
            handleSetState(val);
          }
          if (onChange) {
            onChange(val);
          }
        }}
        placeholder={placeholder}
        classNames={{ control: (state) => (isMulti ? 'custom-select-multi' : 'custom-select') }}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: state.isFocused ? colors.cameo : error ? colors.red : colors.alto,
            backgroundColor: state.isDisabled ? `${colors.disabled} !important` : colors.backgroung,
            minHeight: '44px',
            maxHeight: isMulti ? 'auto' : '44px',
            boxShadow: '0px',
          }),
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        }}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        onMenuScrollToBottom={onFocus}
        theme={customTheme}
        isMulti={isMulti ? isMulti : false}
        isDisabled={disabled}
        menuPortalTarget={document.body}
        menuPlacement="auto"
        isLoading={isLoading}
        {...props}
      />
    </div>
  );
};

export default Select;
