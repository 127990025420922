import { useState } from 'react';
import Button from '../../../../components/Button/Button';
import FormHeading from '../../../../components/Typography/FormHeading';
import { DEAL_STEPS } from '../../../../constants';
import CarDelivery from '../CommonBankSteps/CarDelivery';
import LoaderInline from '../../../../components/Loader/LoaderInline';

const BerekeFlow = ({ step, deal, goBack, update }) => {
  const [loading, setLoading] = useState(false);

  if (loading) {
    return <LoaderInline />;
  }
  if (step === DEAL_STEPS.BEREKE.WAITING_FOR_CLIENT_ACTIONS) {
    return (
      <>
        <FormHeading>Статус</FormHeading>
        <Button
          text={'Клиенту необходимо обратиться в банк для дальнейшего оформления'}
          style={{ color: 'rgb(73, 65, 54)', border: '1px solid rgb(73, 65, 54)' }}
        />
      </>
    );
  }
  if (step === DEAL_STEPS.BEREKE.CAR_DELIVERY) {
    <CarDelivery deal={deal} loading={loading} setLoading={setLoading} onSuccess={update}/>;
  }
};

export default BerekeFlow;
