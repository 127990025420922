import DealCredits from '../DealCredits';

const DealCreateBanks = ({deal, onSubmit, backHandler}) => {
  return (
    <>
      <DealCredits dealId={deal?.id} deal={deal} onSubmit={onSubmit} backHandler={backHandler} />
    </>
  );
};

export default DealCreateBanks;
