// export const apiUrl = "http://localhost:59633"
export const apiUrl = process.env.REACT_APP_API_URL;
export const apiAdminUrl = process.env.REACT_APP_API_ADMIN_URL;
export const localApiUrl = process.env.REACT_APP_LOCAL_API_URL;
// export const apiUrl = 'https://test-app.doscar.kz';
// export const apiAdminUrl = 'https://test-admin.doscar.kz';
export const PAYMENT_TYPES_PREPAYMENT_INVOICE = 4;
export const PAYMENT_TYPES_PREPAYMENT_CARD = 5;
export const PAYMENT_TYPES_CREDIT_CARD = 7;
export const PAYMENT_TYPES_CREDIT_INVOICE = 6;
export const PAYMENT_TYPES_OPTIONS = [
  { value: PAYMENT_TYPES_PREPAYMENT_INVOICE, label: 'Предоплата (счет на оплату)' },
  { value: PAYMENT_TYPES_PREPAYMENT_CARD, label: 'Предоплата (оплата картой)' },
  { value: PAYMENT_TYPES_CREDIT_INVOICE, label: 'Автокредит' },
  // { value: PAYMENT_TYPES_CREDIT_CARD, label: 'Автокредит (оплата картой)' },
];

export const CREDIT_TYPE_ID = 3;
export const INSURANCE_ID = 6;

export const INSURANCE_SERVICE_ID_2_5 = 8;
export const INSURANCE_SERVICE_ID_3 = 6;
export const INSURANCE_SERVICE_ID_3_5 = 7;

export const DOCUMENT_TYPES_INVOICE = 1;
export const DOCUMENT_TYPES_CONTRACT = 2;
export const DOCUMENT_TYPES_POLES = 3;
export const DOCUMENT_TYPES_PAYMENT_CONFIRMATION = 4;
export const DOCUMENT_TYPES_PERSONAL_DATA_AGREEMENT = 5;
export const DOCUMENT_TYPES_POWER_OF_ATTORNEY = 7;
export const DOCUMENT_TYPES_SPOUSE_CONSENT = 8;
export const DOCUMENT_TYPES_TECH_PASSPORT = 9;
export const DOCUMENT_TYPES_CLIENT_BANK_DOSCAR_AGREEMENT = 19;
export const DOCUMENT_TYPES_APPLICATION_FOR_ADDITIONAL_SERVICES = 20;

export const DOCUMENT_TYPES_GRNZ = 12;
export const DOCUMENT_TYPES_PLEDGE_AGREEMENT = 13;
export const DOCUMENT_TYPES_PLEDGE_APPLICATION = 14;
export const DOCUMENT_TYPES_UDV = 15;
export const DOCUMENT_TYPES_UDV_SPOUSE = 16;
export const DOCUMENT_TYPES_NO_TAX_DEBT = 17;
export const DOCUMENT_TYPES_APPLICATION_PREMIUM_AND_ADDITIONAL_EQUIPMENT_LOAN = 18;
export const DOCUMENT_TYPES_GUARANTEE_LETTER = 23;
export const DOCUMENT_TYPES_CERTIFICATE_OF_CIRCUMSTANCES = 24;
export const DOCUMENT_TYPES_PAYMENT_ORDER = 25;

export const DOCUMENT_STATUS_SIGNED = 3;

export const DOCUMENT_TYPES_OPTIONS = [
  { value: DOCUMENT_TYPES_INVOICE, label: 'Счет на оплату' },
  { value: DOCUMENT_TYPES_CONTRACT, label: 'Договор купли продажи' },
  { value: DOCUMENT_TYPES_POLES, label: 'Полис' },
  { value: DOCUMENT_TYPES_PAYMENT_CONFIRMATION, label: 'Подтверждение оплаты' },
  { value: DOCUMENT_TYPES_PERSONAL_DATA_AGREEMENT, label: 'Соглашение на обработку персональных данных' },
  { value: DOCUMENT_TYPES_SPOUSE_CONSENT, label: 'Согласие супруги' },
  { value: DOCUMENT_TYPES_POWER_OF_ATTORNEY, label: 'Доверенность' },
  { value: DOCUMENT_TYPES_TECH_PASSPORT, label: 'Техпаспорт (скан)' },
  { value: DOCUMENT_TYPES_GUARANTEE_LETTER, label: 'Гарантийное письмо' },
  { value: DOCUMENT_TYPES_PAYMENT_ORDER, label: 'Платежное поручение банк' },
];

export const MARITAL_STATUS_UNDEFINED = 0;
export const MARITAL_STATUS_SINGLE = 1;
export const MARITAL_STATUS_MARRIED = 2;
export const MARITAL_STATUS_WIDOWED = 3;
export const MARITAL_STATUS_LIVING_TOGETHER = 4;
export const MARITAL_STATUS_DIVORCED = 5;

export const MARITAL_STATUS_OPTIONS = [
  {
    label: 'Не указано',
    value: MARITAL_STATUS_UNDEFINED,
  },
  {
    label: 'Холост/Не замужем',
    value: MARITAL_STATUS_SINGLE,
  },
  {
    label: 'Женат/Замужем',
    value: MARITAL_STATUS_MARRIED,
  },
  {
    label: 'Вдовец/Вдова',
    value: MARITAL_STATUS_WIDOWED,
  },
  {
    label: 'Гражданский брак',
    value: MARITAL_STATUS_LIVING_TOGETHER,
  },
  {
    label: 'Разведен',
    value: MARITAL_STATUS_DIVORCED,
  },
];

export const CLIENT_DOCUMENT_TYPE = [
  { value: 1, label: "Удостоверение личности" },
  { value: 5, label: "Дипломатический паспорт" },
  { value: 2, label: "Паспорт" },
  { value: 4, label: "Вид на жительство иностранца" },
  { value: 8, label: "Лицо без гражданства РК" },
];

export const CLIENT_DOCUMENT_ISSUED_BY = [
  { value: 1, label: 'МВД РК', code: 'MINISTRY_OF_THE_INTERIOR' },
  { value: 2, label: 'МЮ РК', code: 'MINISTRY_OF_JUSTICE' },
];

export const CLIENT_NOTARIAL_VERIFICATION_PENDING = 1;
export const CLIENT_NOTARIAL_VERIFICATION_APPROVED = 2;
export const CLIENT_NOTARIAL_VERIFICATION_REJECTED = 3;

export const CLIENT_NOTARIAL_VERIFICATION_OPTIONS = [
  { value: CLIENT_NOTARIAL_VERIFICATION_PENDING, label: 'Ожидает проверки' },
  { value: CLIENT_NOTARIAL_VERIFICATION_APPROVED, label: 'Прошел проверку' },
  { value: CLIENT_NOTARIAL_VERIFICATION_REJECTED, label: 'Не прошел проверку' },
];

export const PAYMENT_STATUS_PAID = 1;
export const PAYMENT_STATUS_NOT_PAID = 2;

export const DEAL_STATUS_DOCUMENTS_PROCESSING = 1;
export const DEAL_STATUS_FULL_PAYMENT = 2;
export const DEAL_STATUS_REMARKS = 3;
export const DEAL_STATUS_ACT_UPLOADED = 4;
export const DEAL_STATUS_FAILED = 5;
export const DEAL_STATUS_COMPLETED = 6;
export const DEAL_STATUS_NOTARIAL_DOCUMENTS_UPLOADED = 7;
export const DEAL_STATUS_WAITING_FOR_NOTARIAL_DOCUMENTS = 8;
export const DEAL_STATUS_NEED_TO_MAKE_POWER_OF_ATTORNEY = 9;
export const DEAL_STATUS_WAITING_FOR_PAYMENT = 10;
export const DEAL_STATUS_CAR_ISSUED = 11;
export const DEAL_STATUS_WAIRING_REGISTRATION = 12;
export const DEAL_STATUS_WAITING_FOR_SIGNING_CONTRACT = 13;
export const DEAL_STATUS_CONTRACT_SIGNED = 15;
export const DEAL_STATUS_WAITING_FOR_REGISTRATION_BY_CLIENT = 14;
export const DEAL_STATUS_WAITING_FOR_PLEDGE_DOCUMENTS = 17;
export const DEAL_STATUS_WAITING_SIGNING_APPLICATION_FOR_ADDITIONAL_SERVICES = 16;
export const DEAL_STATUS_WAITING_CLIENT_BANK_DOSCAR_AGREEMENT = 18;
export const DEAL_STATUS_WAITING_FOR_REGISTRATION_DOCUMENTS = 17;
export const DEAL_STATUS_READY_FOR_CONCLUSION_OF_CREDIT_AGREEMENT = 19;

export const DEAL_STATUS_WAITING_FOR_FREEDOM = 20;
export const DEAL_STATUS_WAITING_FOR_HALYK = 21;

export const DEAL_STATUS_CAR_DELIVERY = 22;

export const DEAL_STATUSES = [
  { value: DEAL_STATUS_DOCUMENTS_PROCESSING, label: 'Оформление док-в' },
  { value: DEAL_STATUS_FULL_PAYMENT, label: 'Полная оплата' },
  { value: DEAL_STATUS_REMARKS, label: 'Замечания к сделке' },
  { value: DEAL_STATUS_ACT_UPLOADED, label: 'Акт П/П подгружен' },
  { value: DEAL_STATUS_FAILED, label: 'Сделка провалена' },
  { value: DEAL_STATUS_COMPLETED, label: 'Завершена' },
  { value: DEAL_STATUS_NOTARIAL_DOCUMENTS_UPLOADED, label: 'Нотариальные документы оформлены' },
  { value: DEAL_STATUS_WAITING_FOR_NOTARIAL_DOCUMENTS, label: 'Ожидает нотариальных документов' },
  {
    value: DEAL_STATUS_NEED_TO_MAKE_POWER_OF_ATTORNEY,
    label: 'Необходимо оформить доверенность на постановку на учет',
  },
  { value: DEAL_STATUS_WAITING_FOR_PAYMENT, label: 'Ожидает оплаты' },
  { value: DEAL_STATUS_WAIRING_REGISTRATION, label: 'Ожидает постановки на учет' },
  { value: DEAL_STATUS_CAR_ISSUED, label: 'Машина выдана' },
  { value: DEAL_STATUS_WAITING_FOR_SIGNING_CONTRACT, label: 'Ожидает подписания договора' },
  { value: DEAL_STATUS_CONTRACT_SIGNED, label: 'Договор подписан' },
  { value: DEAL_STATUS_WAITING_FOR_REGISTRATION_BY_CLIENT, label: 'Ожидает постановки на учет клиентом' },
];

export const CAR_DOCUMENT_TYPES = [
  { value: 1, label: 'Свидетельство о регистрации ТС' },
  { value: 2, label: 'ПТС' },
  { value: 3, label: 'Сертификат об оплате утилизационного сбора' },
  { value: 4, label: 'Сертификат соответствия' },
  { value: 5, label: 'Сертификат о прохождении таможенной очистки' },
];

export const CLIENT_CONTACT_PERSON_TYPES = [
  { value: "Жена", label: "Жена" },
  { value: "Муж", label: "Муж" },
  { value: "Мама", label: "Мама" },
  { value: "Отец", label: "Отец" },
  { value: "Дочь", label: "Дочь" },
  { value: "Сын", label: "Сын" },
  { value: "Брат", label: "Брат" },
  { value: "Сестра", label: "Сестра" },
  { value: "Коллеги", label: "Коллеги" },
  { value: "Друзья", label: "Друзья" },
];

export const RESIDENT_TYPES = [
  { value: 1, label: "Резидент" },
  { value: 0, label: "Не резидент" },
];

export const BANKS = {
  EURAZ: 1,
  BCK: 2,
  FREEDOM: 3,
  BEREKE: 4,
  // HALYK: 5,
  HALYK: 5,
  FORTE: 6,
  SHINHAN: 7,
  TEST: 100,
};

export const BANKS_OPTIONS = [
  { value: BANKS.EURAZ, label: 'EURAZ',  },
  { value: BANKS.BCK, label: 'BCK', image: "http://app.doscar.kz/images/banks/logo/bcc.png", },
  { value: BANKS.FREEDOM, label: 'FREEDOM' },
  { value: BANKS.BEREKE, label: 'BEREKE', image: "http://app.doscar.kz/images/banks/logo/Bereke.png", },
  { value: BANKS.HALYK, label: 'HALYK', image: "http://app.doscar.kz/images/banks/logo/halyk.png", },
  { value: BANKS.FORTE, label: 'FORTE', image: "http://app.doscar.kz/images/banks/logo/forte.png" },
  { value: BANKS.SHINHAN, label: 'SHINHAN', image: "http://app.doscar.kz/images/banks/logo/shinhan.png"},
];

export const DEAL_STEPS = {
  FINISH: 10,
  CLIENT_PAYMENT_APPROVE: 100,
  HALYK: {
    NOTARIAL_VERIFICATION: 20,
    WAITING_FOR_PAYMENT: 21,
    AUTO_REGISTRATION: 22,
    WAITING_FOR_FINAL_ACTIONS: 23,
    CAR_DELIVERY: 24,
  },
  FREEDOM: {
    WAITING_FOR_CLIENT_ACTIONS_ON_FREEDOM_SITE: 31,
    WAITING_FOR_CLIENT_DOCUMENTS: 32,
    WAITING_FOR_PAYMENT: 33,
    AUTO_REGISTRATION: 34,
    WAITING_FOR_FINAL_ACTIONS: 35,
  },
  FORTE: {
    NOTARIAL_VERIFICATION: 41,
    WAITING_FOR_CLIENT_DOCUMENTS: 42,
    WAITING_FOR_PAYMENT: 43,
    AUTO_REGISTRATION: 44,
    AUTO_REREGISTRATION: 49,
    WAITING_FOR_FINAL_ACTIONS: 45,
    CAR_DELIVERY: 46,
    WAITING_FOR_CLIENT_ACTIONS_ON_FORTE_MOBILE_APP: 47,
    WAITING_FOR_CLIENT_ACTIONS_ON_FORTE_SIDE: 48,
    WAITING_NEW_STATUS: 411,
    SIGN_CONTRACT: 422,
  },
  BEREKE: {
    WAITING_FOR_CLIENT_ACTIONS: 50,
    CAR_DELIVERY: 51,
  },
  BCK: {
    NOTARIAL_VERIFICATION: 61,
    WAITING_FOR_PAYMENT: 62,
    WAITING_BANK_ACTIONS: 63,
    AUTO_REREGISTRATION: 64,
    CAR_DELIVERY: 65,
  }
};

