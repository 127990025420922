import React, { useState } from 'react';
import MaskedTextInput from 'react-text-mask';
import styles from './input.module.css';
import stylesError from '../../HookForm/form.module.css';
import formatNumber from '../../../utils/formatNumber';

import { textStyles } from '../../../constants/textStyles';
import { colors } from '../../../constants/colors';
import Hint from '../../Hint';

const creditValue = 6;

const Input = ({
  label,
  value,
  defaultValue,
  onChange,
  placeholder,
  disabled,
  icon,
  color,
  style = {},
  styleWrapper,
  inputType,
  mask,
  format,
  error,
  errorMessage,
  required,
  dependantValue,
  hide,
  dependencyAccessor = (obj) => obj,
  hint,
  maxLength,
  ...props
}) => {
  const [formatPrice, setFormatPrice] = useState(value);

  if (props.isCredit) {
    if (dependencyAccessor(dependantValue) !== creditValue && dependencyAccessor(dependantValue) !== 7) return null;
  }
  if (hide && hide(dependencyAccessor && dependantValue ? dependencyAccessor(dependantValue) : null)) return null;

  if (props.isFee) {
    if (dependantValue?.length > 1) {
      const carPrice = dependantValue[1]?.reduce((acc, item) => acc + item?.price, 0);

      if (value < carPrice * 0.1) {
        errorMessage = 'Минимальная сумма взноса 10% от стоимости автомобиля';
        error = true;
      } else if (value > carPrice) {
        errorMessage = 'Сумма взноса не может превышать стоимость автомобиля';
        error = true;
      } else {
        errorMessage = false;
        error = false;
      }
    }
  }

  const conditionalStyles = {
    ...style,
  };

  if (error) {
    conditionalStyles.borderColor = colors.red;
  }
  if (icon || color) {
    conditionalStyles.paddingLeft = '45px';
  }


  return (
    <div className={styles.input} style={styleWrapper}>
      {label && (
        <label style={textStyles.fieldLabel}>
          {label} {required && <span style={{ color: colors.red }}>*</span>}
          {hint && <Hint text={hint} />}
        </label>
      )}

      <div className={styles.block}>
        {icon && <img src={icon} alt="Icon" />}
        {color && (
          <span
            style={{
              backgroundColor: color,
              width: 20,
              height: 20,
              display: 'block',
              position: 'absolute',
              top: 12,
              left: 12,
              borderRadius: '50%',
              border: '1px solid #d9d9d9'
            }}
          ></span>
        )}
        {mask ? (
          <MaskedTextInput
            style={conditionalStyles}
            type={'text'}
            placeholder={placeholder}
            mask={mask}
            onChange={onChange}
            autoComplete="off"
            value={value}
            disabled={disabled}
            id="input"
            defaultValue={defaultValue}
          />
        ) : (
          <input
            style={conditionalStyles}
            type={inputType ?? 'text'}
            id="input"
            placeholder={placeholder}
            onChange={(e) => {
              onChange(e);
              if (format === 'number') {
                setFormatPrice(() => e.target.value.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ' '));
              }
            }}
            autoComplete="off"
            value={format === 'number' ? formatPrice : value}
            disabled={disabled}
            defaultValue={defaultValue}
            maxLength={maxLength}
          />
        )}
        {errorMessage && (
          <div className={stylesError.error}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <g clip-path="url(#clip0_3320_14643)">
                <path
                  d="M8.00016 14.6666C4.31816 14.6666 1.3335 11.682 1.3335 7.99998C1.3335 4.31798 4.31816 1.33331 8.00016 1.33331C11.6822 1.33331 14.6668 4.31798 14.6668 7.99998C14.6668 11.682 11.6822 14.6666 8.00016 14.6666ZM8.00016 13.3333C9.41465 13.3333 10.7712 12.7714 11.7714 11.7712C12.7716 10.771 13.3335 9.41447 13.3335 7.99998C13.3335 6.58549 12.7716 5.22894 11.7714 4.22874C10.7712 3.22855 9.41465 2.66665 8.00016 2.66665C6.58567 2.66665 5.22912 3.22855 4.22893 4.22874C3.22873 5.22894 2.66683 6.58549 2.66683 7.99998C2.66683 9.41447 3.22873 10.771 4.22893 11.7712C5.22912 12.7714 6.58567 13.3333 8.00016 13.3333ZM7.3335 9.99998H8.66683V11.3333H7.3335V9.99998ZM7.3335 4.66665H8.66683V8.66665H7.3335V4.66665Z"
                  fill="#EB5757"
                />
              </g>
              <defs>
                <clipPath id="clip0_3320_14643">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <p>{errorMessage || 'Это поле обязательно для заполнения'}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Input;
