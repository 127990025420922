import SelectClients from '../components/Selects/SelectClients';
import SelectStatuses from '../components/Selects/SelectStatuses';
import SelectDealPaymentStatuses from '../components/Selects/selectDealPaymentStatuses';
import Datepicker from '../components/Inputs/Datepicker/Datepicker';
import SelectPaymentTypes from '../components/Selects/SelectPaymentTypes';
import Select from '../components/Select/Select';
import SelectManager from '../components/Selects/SelectManager';
import SelectContainers from '../components/Selects/SelectContainers';
import SelectSources from '../components/Selects/SelectSources';
import SelectDealStatuses from '../components/Selects/SelectDealStatuses';
import SelectComplectations from '../components/Selects/SelectComplectations';
import SelectStockCar from '../components/Selects/SelectStockCar';
import SelectCity from '../components/Selects/SelectCity';
import SelectMarks from '../components/Selects/SelectMarks';
import SelectModels from '../components/Selects/SelectModels';

export const clientOptions = [
  { name: 'fio', label: 'ФИО клиента', placeholder: 'ФИО клиента' },
  { name: 'iin', label: 'ИИН', placeholder: 'ИИН клиента' },
  {
    name: 'vip',
    component: Select,
    label: 'VIP',
    placeholder: 'Выберите',
    options: [
      { value: true, label: 'Да' },
      { value: false, label: 'Нет' },
    ],
  },
  { name: 'company', label: 'Компания', placeholder: 'Компания' },
  { name: 'role', label: 'Должность', placeholder: 'Должность' },
  { name: 'phone', label: 'Телефон', placeholder: 'Телефон' },
  { name: 'email', label: 'E-mail', placeholder: 'E-mail' },
  {
    name: 'broker',
    label: 'Посредник',
    component: Select,
    placeholder: 'Выберите',
    options: [
      { value: true, label: 'Да' },
      { value: false, label: 'Нет' },
    ],
  },
];

export const servicesOptions = [
  { name: 'id', label: 'ИД', placeholder: 'Введите ИД' },
  { name: 'title', label: 'Название', placeholder: 'Введите название' },
  { name: 'price', label: 'Цена', placeholder: 'Введите цену' },
];

export const leadOptions = [
  { fio_label: 'ФИО лида', fio_placeholder: 'ФИО лида' },
  { iin_label: 'ИИН', iin_placeholder: 'ИИН лида' },
  { vip_label: 'VIP', vip_placeholder: 'Выберите' },
  { company_label: 'Компания', company_placeholder: 'Компания' },
  { role_label: 'Должность', role_placeholder: 'Должность' },
  { phone_label: 'Телефон', phone_placeholder: 'Телефон' },
  { email_label: 'E-mail', email_placeholder: 'E-mail' },
  { broker_label: 'Посредник', broker_placeholder: 'Выберите' },
];

export const dealOptions = [
  { fio_label: 'ФИО лида', fio_placeholder: 'ФИО лида' },
  { iin_label: 'ИИН', iin_placeholder: 'ИИН лида' },
  { vip_label: 'VIP', vip_placeholder: 'Выберите' },
  { company_label: 'Компания', company_placeholder: 'Компания' },
  { role_label: 'Должность', role_placeholder: 'Должность' },
  { phone_label: 'Телефон', phone_placeholder: 'Телефон' },
  { email_label: 'E-mail', email_placeholder: 'E-mail' },
  { broker_label: 'Посредник', broker_placeholder: 'Выберите' },
];

export const carsOptions = [
  // { name: 'containerId', component: SelectContainers },
  // { name: 'complectationId', component: SelectComplectations, params: () => carsOptions[0]?.value?.value },
  { name: 'markId', component: SelectMarks },
  {
    name: 'modelId', component: SelectModels,
    dependency: 'markId',
    dependencyAccessor: (obj) => obj?.value,
  },
  { name: 'stock', component: SelectStockCar, label: 'В наличии' },
  { name: 'cityId', component: SelectCity, label: 'Дилерский центр', placeholder: 'Выберите из списка' },
];

export const equipmentOptions = [
  { name: 'id', label: 'ИД', placeholder: 'Введите ИД' },
  { name: 'title', label: 'Название', placeholder: 'Введите название' },
  { name: 'price', label: 'Цена', placeholder: 'Введите цену' },
  {
    name: 'typeId',
    component: Select,
    label: 'Тип',
    placeholder: 'Выберите из списка',
    options: [
      { value: 1, label: 'Комплект' },
      { value: 2, label: 'Штука' },
    ],
  },
];

export const reportOptions = [
  { fio_label: 'Дата', fio_placeholder: 'Введите' },
  { iin_label: 'ИИН', iin_placeholder: 'ИИН лида' },
  { vip_label: 'VIP', vip_placeholder: 'Выберите' },
  { company_label: 'Компания', company_placeholder: 'Компания' },
  { role_label: 'Должность', role_placeholder: 'Должность' },
  { phone_label: 'Телефон', phone_placeholder: 'Телефон' },
  { email_label: 'E-mail', email_placeholder: 'E-mail' },
  { broker_label: 'Посредник', broker_placeholder: 'Выберите' },
];

export const leadsOptions = [
  { name: 'createdAt', component: Datepicker, label: 'Дата', placeholder: 'дд-мм-гггг' },
  { name: 'clientId', component: SelectClients },
  { name: 'managerId', component: SelectManager, label: 'Менеджер', placeholder: 'Выберите из списка' },
  { name: 'fio', label: 'ФИО клиента', placeholder: 'ФИО клиента' },
  { name: 'markAndModel', component: SelectContainers, label: 'Марка и модель', placeholder: 'Выберите из списка' },
  { name: 'sourceId', component: SelectSources, label: 'Источник', placeholder: 'Выберите из списка' },
  { name: 'phone', label: 'Телефон', placeholder: 'Телефон' },
  { name: 'email', label: 'E-mail', placeholder: 'E-mail' },
  { name: 'statusId', component: SelectStatuses, label: 'Статус', placeholder: 'Выберите статус сделки' },
];

export const dealsOptions = [
  { name: 'createdAt', component: Datepicker, label: 'Дата', placeholder: 'дд-мм-гггг' },
  { name: 'clientId', component: SelectClients },
  { name: 'sum', label: 'Сумма контракта, KZT', placeholder: 'Введите сумму контракта' },
  { name: 'statusPaymentId', component: SelectDealPaymentStatuses, placeholder: 'Выберите статус сделки' },
  {
    name: 'statusId',
    component: SelectDealStatuses,
    label: 'Статус сделки',
    placeholder: 'Выберите статус сделки',
    defaultValue: { paymentTypeId: 0 },
  },
  { name: 'paymentType', component: SelectPaymentTypes, label: 'Форма оплаты', placeholder: 'Выберите форму оплаты' },
];
