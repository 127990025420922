import { useCallback, useEffect } from 'react';
import { useState } from 'react';
import getComplectations from '../../api/cars/getComplectations';
import Select from '../Select/Select';

const SelectComplectations = ({ handleSetSelect, handleSetState, style, params, ...props }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const onFocus = useCallback(() => {
    setLoading(true);
    getComplectations({ containerId: params() }).then((res) => {
      const complectations = res.data.map((item) => {
        return { value: item.id, label: item.title };
      });
      console.log(complectations);
      setData(complectations);
    }).finally(() => setLoading(false));
  }, []);
  useEffect(() => {
    console.log(params);
  }, []);
  return (
    <Select
      handleSetSelect={(val) => handleSetSelect && handleSetSelect(val)}
      handleSetState={handleSetState}
      options={data}
      title={'Комплектация'}
      placeholder={'Выберите из списка'}
      onFocus={onFocus}
      style={style}
      isLoading={loading}
      {...props}
    />
  );
};

export default SelectComplectations;
