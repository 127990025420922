import React, { useEffect, useState } from 'react';
import DocsInputNew from '../../../components/Inputs/DocsInput/DocsInputNew';
import HookForm from '../../../components/HookForm/HookForm';
import {
  DEAL_STATUS_NOTARIAL_DOCUMENTS_UPLOADED,
  DEAL_STATUS_WAIRING_REGISTRATION,
  DEAL_STATUS_WAITING_CLIENT_BANK_DOSCAR_AGREEMENT,
  DEAL_STATUS_WAITING_FOR_NOTARIAL_DOCUMENTS,
  DEAL_STATUS_WAITING_FOR_PAYMENT,
  DEAL_STATUS_WAITING_FOR_PLEDGE_DOCUMENTS,
  DEAL_STATUS_WAITING_FOR_REGISTRATION_DOCUMENTS,
  DEAL_STATUS_WAITING_SIGNING_APPLICATION_FOR_ADDITIONAL_SERVICES,
  DEAL_STEPS,
  DOCUMENT_TYPES_APPLICATION_FOR_ADDITIONAL_SERVICES,
  DOCUMENT_TYPES_APPLICATION_PREMIUM_AND_ADDITIONAL_EQUIPMENT_LOAN,
  DOCUMENT_TYPES_CLIENT_BANK_DOSCAR_AGREEMENT,
  DOCUMENT_TYPES_GRNZ,
  DOCUMENT_TYPES_GUARANTEE_LETTER,
  DOCUMENT_TYPES_INVOICE,
  DOCUMENT_TYPES_NO_TAX_DEBT,
  DOCUMENT_TYPES_PAYMENT_ORDER,
  DOCUMENT_TYPES_PLEDGE_AGREEMENT,
  DOCUMENT_TYPES_PLEDGE_APPLICATION,
  DOCUMENT_TYPES_POWER_OF_ATTORNEY,
  DOCUMENT_TYPES_SPOUSE_CONSENT,
  DOCUMENT_TYPES_TECH_PASSPORT,
  DOCUMENT_TYPES_UDV,
  PAYMENT_STATUS_PAID,
} from '../../../constants';
import SelectDealPaymentStatuses from '../../../components/Selects/selectDealPaymentStatuses';
import editDeal from '../../../api/deals/editDeal';
import setStep from '../../../api/deals/setStep';
import alert from '../../../helpers/alert';
import uploadDocumentToDeal from '../../../api/deals/uploadDocumentToDeal';
import Button from '../../../components/Button/Button';
import { socket } from '../../../App';
import generateAllowedDocumentsJson from '../../../utils/generateAllowedDocumentsJson';
import DownloadFileLink from '../../../components/DownloadFileLink/DownloadFileLink';
import HalykNotarialDocuments from './HalykFlow/HalykNotarialDocuments';
import sendMarriedDoc from '../../../api/creditPlatform/sendMarriedDoc';

const DealCreateCreditDocuments = ({ documents, backHandler, deal, update, chosenBankAppealId }) => {
  const [loading, setLoading] = useState(false);
  const [agreementClientBankDoscar, setAgreementClientBankDoscar] = useState(null);
  const [applicationAdditionalServices, setApplicationAdditionalServices] = useState(null);

  const onAgreementFormSubmit = () => {
    if (!agreementClientBankDoscar) {
      return alert.error('Необходимо загрузить соглашение');
    }

    setLoading(true);

    uploadDocumentToDeal({
      dealId: deal?.id,
      fileTypeId: DOCUMENT_TYPES_CLIENT_BANK_DOSCAR_AGREEMENT,
      file: agreementClientBankDoscar,
      signed: true,
    })
      .then(() => {
        editDeal({
          id: deal?.id,
          statusId: DEAL_STATUS_WAITING_FOR_PAYMENT,
          ...generateAllowedDocumentsJson([DOCUMENT_TYPES_CLIENT_BANK_DOSCAR_AGREEMENT, DOCUMENT_TYPES_INVOICE, DOCUMENT_TYPES_GUARANTEE_LETTER]),
        })
          .then(() => {
            update && update();
          })
          .catch(() => {
            alert.error('Ошибка обновления статуса сделки');
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch(() => {
        alert.error('Ошибка загрузки соглашения');
        setLoading(false);
      });
  };

  const onPaymentFormSubmit = (values) => {
    setLoading(true);

    editDeal({
      id: deal?.id,
      statusPaymentId: values?.status?.value,
      statusId:
        values?.status?.value === PAYMENT_STATUS_PAID
          ? DEAL_STATUS_WAITING_SIGNING_APPLICATION_FOR_ADDITIONAL_SERVICES
          : deal?.status?.id,
      ...generateAllowedDocumentsJson([
        DOCUMENT_TYPES_CLIENT_BANK_DOSCAR_AGREEMENT,
        DOCUMENT_TYPES_INVOICE,
        DOCUMENT_TYPES_APPLICATION_FOR_ADDITIONAL_SERVICES,
        DOCUMENT_TYPES_GUARANTEE_LETTER,
      ]),
    })
      .then(() => {
        alert.success('Статус оплаты успешно обновлен');
        update && update();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onApplicationFormSubmit = () => {
    setLoading(true);

    if (!applicationAdditionalServices) {
      return alert.error('Необходимо загрузить соглашение');
    }

    uploadDocumentToDeal({
      dealId: deal?.id,
      fileTypeId: DOCUMENT_TYPES_APPLICATION_FOR_ADDITIONAL_SERVICES,
      file: applicationAdditionalServices,
    })
      .then(() => {
        editDeal({
          id: deal?.id,
          statusId: DEAL_STATUS_WAITING_FOR_NOTARIAL_DOCUMENTS,
          ...generateAllowedDocumentsJson([
            DOCUMENT_TYPES_CLIENT_BANK_DOSCAR_AGREEMENT,
            DOCUMENT_TYPES_INVOICE,
            DOCUMENT_TYPES_APPLICATION_FOR_ADDITIONAL_SERVICES,
            DOCUMENT_TYPES_GUARANTEE_LETTER,
          ]),
        })
          .then(() => {
            update && update();
          })
          .catch(() => {
            alert.error('Ошибка обновления статуса сделки');
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch(() => {
        alert.error('Ошибка загрузки соглашения');
        setLoading(false);
      });
  };

  const onCarDocumentsFormSubmit = async (files) => {
    if (!files['Техпаспорт'] || !files['ГРНЗ']) {
      return alert.error('Необходимо загрузить все документы');
    }

    try {
      await uploadDocumentToDeal({
        dealId: deal?.id,
        fileTypeId: DOCUMENT_TYPES_GRNZ,
        file: files['ГРНЗ'],
      });

      await uploadDocumentToDeal({
        dealId: deal?.id,
        fileTypeId: DOCUMENT_TYPES_TECH_PASSPORT,
        file: files['Техпаспорт'],
      });

      editDeal({
        id: deal?.id,
        statusId: DEAL_STATUS_WAITING_FOR_NOTARIAL_DOCUMENTS,
      })
        .then(() => {
          update && update();
        })
        .catch(() => {
          alert.error('Ошибка обновления статуса сделки');
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      alert.error('Ошибка загрузки документов');
      setLoading(false);
    }
  };

  const notarialDocumentsUploadedFormSubmit = () => {
    editDeal({
      id: deal?.id,
      statusId: DEAL_STATUS_WAITING_FOR_PLEDGE_DOCUMENTS,
      ...generateAllowedDocumentsJson([
        DOCUMENT_TYPES_CLIENT_BANK_DOSCAR_AGREEMENT,
        DOCUMENT_TYPES_INVOICE,
        DOCUMENT_TYPES_APPLICATION_FOR_ADDITIONAL_SERVICES,
        DOCUMENT_TYPES_POWER_OF_ATTORNEY,
        DOCUMENT_TYPES_SPOUSE_CONSENT,
        DOCUMENT_TYPES_GRNZ,
        DOCUMENT_TYPES_TECH_PASSPORT,
        DOCUMENT_TYPES_GUARANTEE_LETTER,
      ]),
    })
      .then(() => {
        update && update();
      })
      .catch(() => {
        alert.error('Ошибка обновления статуса сделки');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onRegistarationDocumentsFormSubmit = async (files) => {
    if (
      !files['Договор залога'] ||
      !files['Заявление о постановке ТС в залог'] ||
      !files['Удостоверение личности'] ||
      !files['Справка об отсутствии налоговой задолженности'] ||
      !files['Заявление о включении']
    ) {
      return alert.error('Необходимо загрузить все документы');
    }

    setLoading(true);
    try {
      await uploadDocumentToDeal({
        dealId: deal?.id,
        fileTypeId: DOCUMENT_TYPES_PLEDGE_AGREEMENT,
        file: files['Договор залога'],
      });

      await uploadDocumentToDeal({
        dealId: deal?.id,
        fileTypeId: DOCUMENT_TYPES_PLEDGE_APPLICATION,
        file: files['Заявление о постановке ТС в залог'],
      });

      await uploadDocumentToDeal({
        dealId: deal?.id,
        fileTypeId: DOCUMENT_TYPES_UDV,
        file: files['Удостоверение личности'],
      });

      await uploadDocumentToDeal({
        dealId: deal?.id,
        fileTypeId: DOCUMENT_TYPES_NO_TAX_DEBT,
        file: files['Справка об отсутствии налоговой задолженности'],
      });

      await uploadDocumentToDeal({
        dealId: deal?.id,
        fileTypeId: DOCUMENT_TYPES_APPLICATION_PREMIUM_AND_ADDITIONAL_EQUIPMENT_LOAN,
        file: files['Заявление о включении'],
      });

      editDeal({
        id: deal?.id,
        statusId: DEAL_STATUS_WAITING_FOR_REGISTRATION_DOCUMENTS,
        ...generateAllowedDocumentsJson([
          DOCUMENT_TYPES_CLIENT_BANK_DOSCAR_AGREEMENT,
          DOCUMENT_TYPES_INVOICE,
          DOCUMENT_TYPES_APPLICATION_FOR_ADDITIONAL_SERVICES,
          DOCUMENT_TYPES_POWER_OF_ATTORNEY,
          DOCUMENT_TYPES_SPOUSE_CONSENT,
          DOCUMENT_TYPES_GRNZ,
          DOCUMENT_TYPES_TECH_PASSPORT,
          DOCUMENT_TYPES_PLEDGE_AGREEMENT,
          DOCUMENT_TYPES_PLEDGE_APPLICATION,
          DOCUMENT_TYPES_UDV,
          DOCUMENT_TYPES_NO_TAX_DEBT,
          DOCUMENT_TYPES_APPLICATION_PREMIUM_AND_ADDITIONAL_EQUIPMENT_LOAN,
          DOCUMENT_TYPES_GUARANTEE_LETTER,
        ]),
      })
        .then(() => {
          setStep({
            dealId: deal?.id,
            step: 7,
          }).then(() => {
            update && update();
          });
        })
        .catch(() => {
          alert.error('Ошибка обновления статуса сделки');
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      alert.error('Ошибка загрузки документов');
      setLoading(false);
    }
  };

  const skipStep = () => {
    setLoading(true);

    setStep({
      dealId: deal?.id,
      step: 7,
      ...generateAllowedDocumentsJson([
        DOCUMENT_TYPES_CLIENT_BANK_DOSCAR_AGREEMENT,
        DOCUMENT_TYPES_INVOICE,
        DOCUMENT_TYPES_APPLICATION_FOR_ADDITIONAL_SERVICES,
        DOCUMENT_TYPES_POWER_OF_ATTORNEY,
        DOCUMENT_TYPES_SPOUSE_CONSENT,
        DOCUMENT_TYPES_GRNZ,
        DOCUMENT_TYPES_TECH_PASSPORT,
        DOCUMENT_TYPES_PLEDGE_AGREEMENT,
        DOCUMENT_TYPES_PLEDGE_APPLICATION,
        DOCUMENT_TYPES_UDV,
        DOCUMENT_TYPES_NO_TAX_DEBT,
        DOCUMENT_TYPES_APPLICATION_PREMIUM_AND_ADDITIONAL_EQUIPMENT_LOAN,
        DOCUMENT_TYPES_GUARANTEE_LETTER,
      ]),
    })
      .then(() => {
        update && update();
      })
      .catch(() => {
        alert.error('Ошибка обновления статуса сделки');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const listenerName = 'listenToChangeDealStatus' + deal?.id;
    socket.on(listenerName, (data) => {
      update && update();
    });
    return () => {
      socket.off(listenerName);
    };
  }, [deal, update]);

  const onNotarialDocumentsSubmit = async (values) => {
    setLoading(true);

    if (!values['согласие']) {
      alert.error('Необходимо загрузить согласие супруга (супруги) на внесудебную реализацию авто');
      setLoading(false);
      return;
    }

    await uploadDocumentToDeal({
      dealId: deal?.id,
      fileTypeId: DOCUMENT_TYPES_SPOUSE_CONSENT,
      file: values['согласие'],
    });
    if (values['доверенность']) {
      await uploadDocumentToDeal({
        dealId: deal?.id,
        fileTypeId: DOCUMENT_TYPES_POWER_OF_ATTORNEY,
        file: values['доверенность'],
      });
    }

    sendMarriedDoc({ appealId: chosenBankAppealId })
      .then(() => {
        editDeal({
          id: deal?.id,
          dealId: deal?.id,
          statusId: DEAL_STATUS_NOTARIAL_DOCUMENTS_UPLOADED,
          power_of_attorney_number: values?.powerOfAttorneyNumber
            ? values?.powerOfAttorneyNumber
            : deal?.power_of_attorney_number,
          ...generateAllowedDocumentsJson([
            DOCUMENT_TYPES_CLIENT_BANK_DOSCAR_AGREEMENT,
            DOCUMENT_TYPES_INVOICE,
            DOCUMENT_TYPES_APPLICATION_FOR_ADDITIONAL_SERVICES,
            DOCUMENT_TYPES_POWER_OF_ATTORNEY,
            DOCUMENT_TYPES_SPOUSE_CONSENT,
            DOCUMENT_TYPES_GUARANTEE_LETTER,
          ]),
        })
          .then(() => {
            update();
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch(() => {
        alert.error('Не удалось отправить согласие супруга (супруги) на внесудебную реализацию авто');
        setLoading(false);
      });
  }

  if (deal?.status?.id === DEAL_STATUS_WAITING_CLIENT_BANK_DOSCAR_AGREEMENT) {
    return (
      <div>
        <HookForm
          title={'Документы'}
          grid={{ display: 'grid', gap: 20 }}
          action={'Сохранить и продолжить'}
          inputs={[]}
          onSubmit={onAgreementFormSubmit}
          loading={loading}
          customData={() => (
            <div
              style={{
                display: 'grid',
                gap: 20,
              }}
            >
              <DocsInputNew
                text="Соглашение между Клиентом, Банком и Doscar"
                label="Загрузить документ"
                id="Соглашение между Клиентом, Банком и Doscar"
                value={agreementClientBankDoscar}
                onChange={setAgreementClientBankDoscar}
              />
            </div>
          )}
          customDataTop={() => (
            <div style={{ marginBottom: 40 }}>
              {documents
                ?.filter((item) => item?.typeId === DOCUMENT_TYPES_CLIENT_BANK_DOSCAR_AGREEMENT)
                .map((document) => (
                  <DownloadFileLink document={document} />
                ))}
            </div>
          )}
          backHandler={backHandler}
        />
      </div>
    );
  }

  if (deal?.status?.id === DEAL_STATUS_WAITING_FOR_PAYMENT) {
    return (
      <div>
        <HookForm
          title={'Документы'}
          grid={{ display: 'grid', gap: 20 }}
          action={'Сохранить и продолжить'}
          inputs={[
            {
              name: 'status',
              component: SelectDealPaymentStatuses,
            },
          ]}
          customDataTop={() => (
            <div style={{ marginBottom: 40 }}>
              {documents
                ?.filter((item) => item?.typeId === DOCUMENT_TYPES_INVOICE)
                .map((document) => (
                  <DownloadFileLink document={document} />
                ))}
            </div>
          )}
          onSubmit={onPaymentFormSubmit}
          loading={loading}
          backHandler={backHandler}
        />
      </div>
    );
  }

  if (deal?.status?.id === DEAL_STATUS_WAITING_SIGNING_APPLICATION_FOR_ADDITIONAL_SERVICES) {
    return (
      <div>
        <HookForm
          title={'Документы'}
          grid={{ display: 'grid', gap: 20 }}
          action={'Сохранить и продолжить'}
          inputs={[]}
          onSubmit={onApplicationFormSubmit}
          loading={loading}
          customData={() => (
            <div
              style={{
                display: 'grid',
                gap: 20,
              }}
            >
              <DocsInputNew
                text="Заявление по доп. услугам банка"
                label="Загрузить документ"
                id="Заявление по доп. услугам банка"
                value={applicationAdditionalServices}
                onChange={setApplicationAdditionalServices}
              />
            </div>
          )}
          customDataTop={() => (
            <div style={{ marginBottom: 40 }}>
              {documents
                ?.filter((item) => item?.typeId === DOCUMENT_TYPES_APPLICATION_FOR_ADDITIONAL_SERVICES)
                .map((document) => (
                  <DownloadFileLink document={document} />
                ))}
            </div>
          )}
          backHandler={backHandler}
        />
      </div>
    );
  }

  if (deal?.status?.id === DEAL_STATUS_WAIRING_REGISTRATION) {
    return (
      <div>
        <HookForm
          title={'Документы'}
          grid={{ display: 'grid', gap: 20 }}
          action={'Сохранить и продолжить'}
          inputs={[
            {
              text: 'ГРНЗ',
              label: 'Загрузить документ',
              name: 'ГРНЗ',
              id: 'ГРНЗ',
              component: DocsInputNew,
            },
            {
              text: 'Техпаспорт',
              label: 'Загрузить документ',
              name: 'Техпаспорт',
              id: 'Техпаспорт',
              component: DocsInputNew,
            },
          ]}
          onSubmit={onCarDocumentsFormSubmit}
          loading={loading}
          backHandler={backHandler}
        />
      </div>
    );
  }

  if (deal?.status?.id === DEAL_STATUS_WAITING_FOR_NOTARIAL_DOCUMENTS) {
    return (
      <HalykNotarialDocuments documents={documents} loading={loading} onSubmit={onNotarialDocumentsSubmit} />
    );
  }

  if (deal?.status?.id === DEAL_STATUS_NOTARIAL_DOCUMENTS_UPLOADED) {
    return (
      <HookForm
        title={'Документы'}
        grid={{ display: 'grid', gap: 20 }}
        action={'Продолжить'}
        inputs={[]}
        onSubmit={notarialDocumentsUploadedFormSubmit}
        loading={loading}
        customData={() => (
          <div>
            <p
              style={{
                fontWeight: 700,
                fontSize: '20px',
                lineHeight: '20px',
                color: 'rgb(73, 65, 54)',
                marginBottom: '20px',
              }}
            >
              Статус
            </p>
            <Button
              text={'НОТАРИАЛЬНЫЕ ДОКУМЕНТЫ ЗАГРУЖЕНЫ'}
              style={{ color: 'rgb(73, 65, 54)', border: '1px solid rgb(73, 65, 54)' }}
            />
          </div>
        )}
        backHandler={backHandler}
      />
    );
  }

  if (deal?.status?.id === DEAL_STATUS_WAITING_FOR_PLEDGE_DOCUMENTS) {
    return (
      <div>
        <HookForm
          title={'Документы'}
          grid={{ display: 'grid', gap: 20 }}
          action={'Сохранить и продолжить'}
          inputs={[
            {
              text: 'Договор залога',
              label: 'Загрузить документ',
              name: 'Договор залога',
              id: 'Договор залога',
              component: DocsInputNew,
              required: true,
            },
            {
              text: 'Заявление о постановке ТС в залог',
              label: 'Загрузить документ',
              name: 'Заявление о постановке ТС в залог',
              id: 'Заявление о постановке ТС в залог',
              component: DocsInputNew,
              required: true,
            },
            {
              text: 'Удостоверение личности',
              label: 'Загрузить документ',
              name: 'Удостоверение личности',
              id: 'Удостоверение личности',
              component: DocsInputNew,
              required: true,
            },
            {
              text: 'Удостоверение личности супруга',
              label: 'Загрузить документ',
              name: 'Удостоверение личности супруга',
              id: 'Удостоверение личности супруга',
              component: DocsInputNew,
            },
            {
              text: 'Справка об отсутствии налоговой задолженности',
              label: 'Загрузить документ',
              name: 'Справка об отсутствии налоговой задолженности',
              id: 'Справка об отсутствии налоговой задолженности',
              component: DocsInputNew,
              required: true,
            },
            {
              text: 'Заявление о включении страховой премии и стоимости доп.оборудования в сумму банковского займа',
              label: 'Загрузить документ',
              name: 'Заявление о включении',
              id: 'Заявление о включении',
              component: DocsInputNew,
              required: true,
            },
          ]}
          onSubmit={onRegistarationDocumentsFormSubmit}
          loading={loading}
          backHandler={backHandler}
        />
      </div>
    );
  }

  return (
    <div>
      <HookForm
        title={'Документы'}
        grid={{ display: 'grid', gap: 20 }}
        action={'Сохранить и продолжить'}
        inputs={[]}
        customDataTop={() => (
          <div style={{ marginBottom: 40 }}>
            {documents
              ?.filter(
                (item) =>
                  item?.typeId === DOCUMENT_TYPES_PLEDGE_AGREEMENT ||
                  item?.typeId === DOCUMENT_TYPES_PLEDGE_APPLICATION ||
                  item?.typeId === DOCUMENT_TYPES_UDV ||
                  item?.typeId === DOCUMENT_TYPES_NO_TAX_DEBT ||
                  item?.typeId === DOCUMENT_TYPES_APPLICATION_PREMIUM_AND_ADDITIONAL_EQUIPMENT_LOAN
              )
              .map((document) => (
                <DownloadFileLink document={document} />
              ))}
          </div>
        )}
        onSubmit={skipStep}
        loading={loading}
        backHandler={backHandler}
      />
    </div>
  );
};

export default DealCreateCreditDocuments;

